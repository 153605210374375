import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

// Update this to your members' endpoint
const API_URL = `${REACT_APP_SERVER_URL}/api`

const getMembers = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const url = `${API_URL}/admin/members/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
  const response = await api.get(url, config)
  return response.data
}

const getMemberTEDSTransactions = async (token, { page = 1, limit = 10, searchTerm = '', memberId }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const url = `${API_URL}/teds/get-member-transactions/?memberId=${memberId}&page=${page}&limit=${limit}&searchTerm=${searchTerm}`
  const transactions = await api.get(url, config)

  return transactions.data
}

const membersService = {
  getMembers,
  getMemberTEDSTransactions,
}

export default membersService
