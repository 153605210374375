import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { login, reset } from '../features/auth/authSlice'
import { useNavigate, Link } from 'react-router-dom'
import Spinner from '../components/Spinner'

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isError) {
      toast.error(message.message)
    }
    // Redirect when logged in

    if (isSuccess || user) {
      navigate('/')
    }

    dispatch(reset())
  }, [isError, isSuccess, user, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {/* Global Container */}
      <div className='flex items-center justify-center'>
        {/* Card Container */}
        <div className='relative flex flex-col m-3 space-y-10 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0 md:m-3'>
          {/* Left Side */}
          <div className='p-6 md:p-20'>
            {/* Top Content */}
            <h2 className='font-mono mb-5 text-4xl font-bold text-left flex'>Log in</h2>
            <p className='max-w-sm mb-12 font-sans font-light text-gray-600 text-left'>Please log in to access your account</p>
            <form onSubmit={onSubmit}>
              <input
                type='text'
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light mb-3'
                placeholder='Enter your email address'
                id='email'
                name='email'
                value={email}
                onChange={onChange}
                required
              />
              <input
                type='password'
                className='w-full p-6 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter your password'
                id='password'
                name='password'
                value={password}
                onChange={onChange}
                required
              />
              {/* Middle Content */}
              <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
                <Link to='/resetpassword' className='font-thin text-cyan-700'>
                  Forgot password?
                </Link>
                <button className='flex p-6 btn-primary text-white w-full md:w-auto font-bold justify-center items-center space-x-4 font-sans rounded-md shadow-lg px-9 shadow-violet-100 hover:bg-opacity-90 hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
                  <span>Submit</span>
                  <img src='images/next-arrow.svg' alt='' />
                </button>
              </div>
            </form>
          </div>
          {/* Right Side */}
          <img src='images/image.jpg' alt='' className='w-[430px] hidden md:block' />
        </div>
      </div>
    </>
  )
}

export default Login
