import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getDailyWalletActivity, reset, resetTedsState } from '../features/teds/tedsSlice'
import Pagination from '../components/Pagination'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const DailyWalletActivity = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedDate, setSelectedDate] = useState(() => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return yesterday
  })

  const {
    walletActivity: { walletActivities, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.teds)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      date: selectedDate,
      page: currentPage,
      limit: 15,
    }
    dispatch(getDailyWalletActivity(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, selectedDate])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    setCurrentPage(1)
    dispatch(resetTedsState())
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='flex mb-8 justify-between items-center mt-2'>
        <DatePicker selected={selectedDate} onChange={handleDateChange} dateFormat='dd/MM/yyyy' className='input w-full max-w-md mr-2' />
      </div>

      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th>User Name</th>
              <th>User Type</th>
              <th>TEDS Incoming</th>
              <th>TEDS Outgoing</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(walletActivities) || walletActivities.length === 0 ? (
              <tr>
                <td colSpan='5'>No wallet activity found for the selected date.</td>
              </tr>
            ) : (
              walletActivities.map((activity, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={activity.walletId._id}>
                  <td>
                    {activity.userAccount.userType === 'Customer' ? (
                      <Link to={`/member-overview/${activity.walletId.userAccount.userId}`} className=''>
                        {activity.walletId.userAccount.userName}
                      </Link>
                    ) : (
                      <Link to={`/partner-overview/${activity.walletId.userAccount.userId}`} className=''>
                        {activity.walletId.userAccount.userName}
                      </Link>
                    )}
                  </td>
                  <td>{activity.userAccount.userType}</td>
                  <td className='text-green-500'>{activity.totalInflow.toFixed(2)}</td>
                  <td className='text-red-500'>{activity.totalOutflow.toFixed(2)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default DailyWalletActivity
