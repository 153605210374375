import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import notificationService from './notificationService'

const initialState = {
  notifications: [],
  loading: false,
  error: null,
}

export const getUnreadNotifications = createAsyncThunk('notifications/getUnread', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await notificationService.getUnreadNotifications(token)
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

export const markNotificationAsRead = createAsyncThunk('notifications/markAsRead', async (id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await notificationService.markNotificationAsRead(id, token)
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUnreadNotifications.pending, (state) => {
        state.loading = true
      })
      .addCase(getUnreadNotifications.fulfilled, (state, action) => {
        state.loading = false
        state.notifications = action.payload
      })
      .addCase(getUnreadNotifications.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        state.notifications = state.notifications.filter((notification) => notification._id !== action.payload.notification._id)
      })
  },
})

export default notificationSlice.reducer
