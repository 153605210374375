import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { resendVerificationEmail } from '../features/auth/authSlice'

function VerifyPrompt() {
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const [cooldown, setCooldown] = useState(5) // Set initial cooldown to 60 so the button will be disabled initially

  const handleResendEmail = () => {
    if (cooldown === 0) {
      dispatch(resendVerificationEmail())
      setCooldown(60)
    }
  }
  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(cooldown - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [cooldown])

  // This effect runs when the component mounts and sends the verification email
  useEffect(() => {
    dispatch(resendVerificationEmail())
  }, [dispatch])

  return (
    <div className='flex flex-col items-center justify-center space-y-4 p-8 bg-white m-12 rounded-2xl'>
      <h2 className='text-2xl font-bold text-blue-700'>Please check your email</h2>
      <p className='text-lg text-gray-700'>
        We've sent an email to {user ? user.email : 'your registered account.'} Please click the link in that email to verify your account.
      </p>
      <p className='text-sm text-gray-600'>If you didn't receive the email, please check your spam folder or request a new verification email.</p>
      <button onClick={handleResendEmail} className='btn btn-secondary' disabled={cooldown > 0}>
        {cooldown > 0 ? `Resend in ${cooldown} s` : 'Resend Verification Email'}
      </button>
    </div>
  )
}

export default VerifyPrompt
