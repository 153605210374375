import { FaSignInAlt, FaBell } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import UserDropdown from './UserDropdown'
import NotificationsDropdown from './NotificationsDropdown'
import '../index.css'

function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)

  const onLogout = () => {
    dispatch(logout())
    setTimeout(function () {
      navigate('/login')
    }, 200)
    console.log('successfully navigated away')
  }

  return (
    <div className='flex justify-between items-center py-3 bg-base-100 border-b-2 stickyHeader mb-5 z-50 bg-black'>
      {/* Logo */}
      <div className='ml-3'>
        <Link to='/' className='normal-case text-xl'>
          <img className={`w-48`} src='/images/logo-dark.svg' alt='Logo' />
        </Link>
      </div>

      {/* Admin User Message */}
      {user && user.role === 'AdminUser' && <div className='text-white text-xl font-bold'>👑 Admin User 🛠️</div>}

      <div className='flex flex-row mr-1'>
        {/* Notifications Dropdown */}
        {user && user.role === 'AdminUser' && <NotificationsDropdown />}
        {/* Login/Logout Buttons */}
        {user ? (
          <UserDropdown user={user} onLogout={onLogout} />
        ) : (
          <Link to='/login' className='btn btn-primary border-none  md:px-4 flex-col justify-center md:flex-row flex items-center'>
            <IconContext.Provider value={{ className: 'md:pr-1 text-xl md:text-2xl' }}>
              <FaSignInAlt />
            </IconContext.Provider>
            <p className='text-[8px] md:text-sm'>Login</p>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Header
