import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env
const API_URL = `${REACT_APP_SERVER_URL}/api`

const registerCharity = async (charityData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = `${API_URL}/admin/register-charity`
    const response = await api.post(url, charityData, config)
    return response.data
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.message
      throw new Error(errorMessage)
    }
    throw error
  }
}

const getAllCharities = async (filterData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        params: filterData,
      },
    }
    const url = `${API_URL}/admin/charities`
    const response = await api.get(url, config)
    return response.data
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.message
      throw new Error(errorMessage)
    }
    throw error
  }
}

const getCharityDetails = async (charityId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = `${API_URL}/admin/charities/${charityId}`
    const response = await api.get(url, config)
    return response.data
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.message
      throw new Error(errorMessage)
    }
    throw error
  }
}

const updateCharity = async (charityData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = `${API_URL}/admin/charities`
    const response = await api.put(url, charityData, config)
    return response.data
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.message
      throw new Error(errorMessage)
    }
    throw error
  }
}

const charityService = {
  registerCharity,
  getAllCharities,
  getCharityDetails,
  updateCharity,
}

export default charityService
