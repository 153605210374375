import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PrivateRoute from './components/PrivateRoute'
import Login from './pages/Login'
import Register from './pages/Register'
import Partners from './pages/Partners'
import Members from './pages/Members'
import PartnerOverview from './pages/PartnerOverview'
import MemberOverview from './pages/MemberOverview'
import NewAgents from './pages/NewAgents'
import Agents from './pages/Agents'
import AgentOverview from './pages/AgentOverview'
import MyReferrals from './pages/MyReferrals'
import ReferredPartners from './pages/ReferredPartners'
import AgentInfo from './pages/AgentInfo'
import AllSales from './pages/AllSales'
import TEDSDailyUpdate from './pages/TEDSDailyUpdate'
import DailyWalletActivity from './pages/DailyWalletActivity'
import AgentSales from './pages/AgentSales'
import FailedRegistrations from './pages/FailedRegistrations'
import OtherBusinesses from './pages/OtherBusinesses'
import AgentSignup from './pages/AgentSignup'
import StrategicPartners from './pages/StrategicPartners.jsx'
import AddStrategicPartner from './pages/AddStrategicPartner'
import ViewStrategicPartner from './pages/ViewStrategicPartner'
import EditStrategicPartner from './pages/EditStrategicPartner'
import AddCharity from './pages/AddCharity'
import ViewCharity from './pages/ViewCharity'
import EditCharity from './pages/EditCharity'
import Charities from './pages/Charities'
import TEDSWallet from './pages/TEDSWallet.jsx'
import TEDSTransactions from './pages/TEDSTransactions.jsx'
import SoldTEDSTransactions from './pages/SoldTEDSTransactions.jsx'
import AgentSettings from './pages/AgentSettings'
import PasswordReset from './pages/PasswordReset'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Header from './components/Header'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import VerifyEmail from './pages/VerifyEmail'
import VerifyPrompt from './pages/VerifyPrompt'
import MemberVerifyEmail from './pages/MemberVerifyEmail'
import BottomNav from './components/BottomNav'
import Home from './pages/Home'
import TEDSHome from './pages/TEDSHome'
import './index.css'

function App() {
  const { user } = useSelector((state) => state.auth)
  return (
    <>
      <Header />

      {user && <BottomNav />}
      <HelmetProvider>
        <div className='container mt-24'>
          <Helmet>
            <meta charSet='utf-8' />
            <title>OLC - Agent Portal</title>
            <link rel='canonical' href='http://mysite.com/example' />
          </Helmet>

          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/agent-sign-up' element={<AgentSignup />} />
            <Route path='/strategic-partners/add' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/strategic-partners/add' element={<AddStrategicPartner />} />
            </Route>
            <Route path='/strategic-partners' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/strategic-partners' element={<StrategicPartners />} />
            </Route>
            <Route path='/charities' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/charities' element={<Charities />} />
            </Route>
            <Route path='/charities/:charityId/edit' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/charities/:charityId/edit' element={<EditCharity />} />
            </Route>
            <Route path='/strategic-partners/:strategicPartnerId/edit' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/strategic-partners/:strategicPartnerId/edit' element={<EditStrategicPartner />} />
            </Route>

            <Route path='/charity/add' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/charity/add' element={<AddCharity />} />
            </Route>
            <Route path='/charity/:charityId/view' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/charity/:charityId/view' element={<ViewCharity />} />
            </Route>
            <Route path='/strategic-partner/:strategicPartnerId/view' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/strategic-partner/:strategicPartnerId/view' element={<ViewStrategicPartner />} />
            </Route>
            <Route path='/resetpassword' element={<PasswordReset />} />
            <Route path='/' element={<PrivateRoute roles={['AdminUser', 'AgentUser']} />}>
              <Route path='/' element={<Home />} />
            </Route>
            <Route path='/verify-email/*' element={<VerifyEmail />} />
            <Route path='/verification-required' element={<VerifyPrompt />} />
            <Route path='/member-verification' element={<MemberVerifyEmail />} />
            <Route path='/partners' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/partners' element={<Partners />} />
            </Route>
            <Route path='/partner-referrals' element={<PrivateRoute roles={['AdminUser', 'AgentUser']} />}>
              <Route path='/partner-referrals' element={<ReferredPartners />} />
            </Route>
            <Route path='/members' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/members' element={<Members />} />
            </Route>
            <Route path='' element={<PrivateRoute roles={['AdminUser', 'AgentUser']} />}>
              <Route path='/member-overview/:memberId/:name' element={<MemberOverview />} />
            </Route>
            <Route path='' element={<PrivateRoute roles={['AdminUser', 'AgentUser']} />}>
              <Route path='/partner-overview/:partnerId?' element={<PartnerOverview />} />
            </Route>
            <Route path='/all-sales' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/all-sales' element={<AllSales />} />
            </Route>
            <Route path='/failed-registrations' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/failed-registrations' element={<FailedRegistrations />} />
            </Route>
            <Route path='/other-businesses' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/other-businesses' element={<OtherBusinesses />} />
            </Route>
            <Route path='/agent-sales' element={<PrivateRoute roles={['AgentUser']} />}>
              <Route path='/agent-sales' element={<AgentSales />} />
            </Route>
            <Route path='/teds' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/teds' element={<TEDSHome />} />
            </Route>
            <Route path='/teds/daily-update' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/teds/daily-update' element={<TEDSDailyUpdate />} />
            </Route>
            <Route path='/teds/wallet' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/teds/wallet' element={<TEDSWallet />} />
            </Route>
            <Route path='/teds/transactions' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/teds/transactions' element={<TEDSTransactions />} />
            </Route>
            <Route path='/teds/daily-wallet-activity' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/teds/daily-wallet-activity' element={<DailyWalletActivity />} />
            </Route>
            <Route path='/settings' element={<PrivateRoute roles={['AgentUser']} />}>
              <Route path='/settings' element={<AgentSettings />} />
            </Route>
            <Route path='/my-referrals' element={<PrivateRoute roles={['AgentUser', 'AdminUser']} />}>
              <Route path='/my-referrals' element={<MyReferrals />} />
            </Route>
            <Route path='/agent-info/:agentId/:agentReferralCode' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/agent-info/:agentId/:agentReferralCode' element={<AgentInfo />} />
            </Route>
            <Route path='/new-agents' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/new-agents' element={<NewAgents />} />
            </Route>
            <Route path='/agents' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/agents' element={<Agents />} />
            </Route>
            <Route path='/teds/sold-teds-transactions' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/teds/sold-teds-transactions' element={<SoldTEDSTransactions />} />
            </Route>
            {/* <Route path='/customer/:id' element={<PrivateRoute roles={['AdminUser']} />}>
              <Route path='/partner/:id' element={<AdminDetails />} />
            </Route> */}
            <Route path='' element={<PrivateRoute roles={['AdminUser', 'AgentUser']} />}>
              <Route path='/agent-overview/:agentId?' element={<AgentOverview />} />
            </Route>
          </Routes>
        </div>
      </HelmetProvider>

      <ToastContainer />
    </>
  )
}

export default App
