import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import { getAgents, reset, acceptAgent as acceptNewAgent } from '../features/agents/agentsSlice'
import Pagination from '../components/Pagination'

import { useDebounce } from '../components/useDebounce'

const NewAgents = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const { agents, isError, isSuccess, isLoading, message } = useSelector((state) => state.agents)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
      isActive: false,
    }
    dispatch(getAgents(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const acceptAgent = (agentID) => {
    dispatch(acceptNewAgent(agentID))
  }

  return (
    <>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <input
        type='text'
        placeholder='Search by name or email'
        value={searchTerm}
        onChange={handleSearchChange}
        className='input w-full max-w-xs  mr-2 '
      />
      {isLoading ? (
        <Spinner />
      ) : !Array.isArray(agents.agents) || agents.agents.length === 0 ? (
        <div className='pt-8 text-4xl font-bold mb-4'>No New Agent Signups found.</div>
      ) : (
        <div className='overflow-x-auto pb-20'>
          <div className='flex mb-8 justify-between mt-2'></div>
          <table className='table mb-16 w-full min-w-full table-zebra'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>Trading Name</th>
                <th>Website</th>
                <th>Address</th>
                <th>Business Address</th>
                <th>Trading Style</th>
                <th>Current Trading Activity</th>
                <th>Company Reg. Number</th>
                <th>VAT Registered</th>
                <th>VAT Reg. Number</th>
                <th>January Sales</th>
                <th>February Sales</th>
                <th>March Sales</th>
                <th>April Sales</th>
                <th>May Sales</th>
                <th>June Sales</th>
                <th>July Sales</th>
                <th>August Sales</th>
                <th>September Sales</th>
                <th>October Sales</th>
                <th>November Sales</th>
                <th>December Sales</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {agents.agents.map((agent, index) => (
                <tr className={index % 2 === 0 ? 'bg-red-200' : 'bg-blue-100'} key={agent.id}>
                  <td>{new Date(agent.createdAt).toLocaleDateString()}</td>
                  <td>{agent.name}</td>
                  <td>{agent.email}</td>
                  <td>{agent.tradingName}</td>
                  <td>{agent.website || 'N/A'}</td>
                  <td>
                    <p>{agent.address?.addressLine1}</p>
                    <p>{agent.address?.addressLine2}</p>
                    <p>{agent.address?.city}</p>
                    <p>{agent.address?.region}</p>
                    <p>{agent.address?.country}</p>
                    <p>{agent.address?.postcode}</p>
                  </td>
                  <td>
                    <p>{agent.businessAddress?.addressLine1}</p>
                    <p>{agent.businessAddress?.addressLine2}</p>
                    <p>{agent.businessAddress?.city}</p>
                    <p>{agent.businessAddress?.region}</p>
                    <p>{agent.businessAddress?.country}</p>
                    <p>{agent.businessAddress?.postcode}</p>
                  </td>
                  <td>{agent.tradingStyle}</td>
                  <td>{agent.currentTradingActivity}</td>
                  <td>{agent.companyRegNumber || 'N/A'}</td>
                  <td>{agent.vatRegistered ? 'No' : 'Yes'}</td>
                  <td>{agent.vatRegNumber || 'N/A'}</td>
                  <td>{agent.january || 'N/A'}</td>
                  <td>{agent.february || 'N/A'}</td>
                  <td>{agent.march || 'N/A'}</td>
                  <td>{agent.april || 'N/A'}</td>
                  <td>{agent.may || 'N/A'}</td>
                  <td>{agent.june || 'N/A'}</td>
                  <td>{agent.july || 'N/A'}</td>
                  <td>{agent.august || 'N/A'}</td>
                  <td>{agent.september || 'N/A'}</td>
                  <td>{agent.october || 'N/A'}</td>
                  <td>{agent.november || 'N/A'}</td>
                  <td>{agent.december || 'N/A'}</td>
                  <td>
                    <button onClick={() => acceptAgent(agent.id)} className='btn-primary btn-sm btn'>
                      Accept Application
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {agents.totalPages > 1 && <Pagination totalPages={agents.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
    </>
  )
}

export default NewAgents
