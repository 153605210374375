import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import BackButton from '../components/BackButton'
import { getTEDSTransactions, fetchTedsWallet } from '../features/teds/tedsSlice'

const TEDSWallet = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  // Calculate the total TEDS balance
  const totalTedsBalance = user.wallet.madTedsBalance + user.wallet.tedsBalance

  const {
    tedsTransactions: { transactions },
  } = useSelector((state) => state.teds)

  const tedsToPounds = (teds) => (teds / 10).toFixed(2) // Convert TEDS to pounds

  useEffect(() => {
    dispatch(getTEDSTransactions({ limit: 5 }))
    dispatch(fetchTedsWallet())
  }, [dispatch])

  return (
    <>
      <div className='flex'>
        <BackButton />
      </div>
      <div className='card md:w-1/2 w-full my-4 mx-auto bg-white'>
        <div className='card-body'>
          <h2 className='card-title mb-4'>TEDS Wallet</h2>
          <div className='flex flex-col md:flex-row justify-between items-center'>
            <div className='flex-1'>
              <div className='flex flex-col'>
                <p className='text-sm'>Available Balance</p>
                <p className='text-lg font-bold'>{totalTedsBalance} TEDS</p>
                <div className='mt-8'>
                  <p className='text-sm'>
                    Approx. <span>£{tedsToPounds(totalTedsBalance)}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='flex flex-col space-y-4 mt-4 md:mt-0'>
              <button className='btn btn-accent'>Sell TEDS</button>
            </div>
          </div>
          <div className='divider'></div>
          <div className='overflow-x-auto'>
            <h3 className='card-title'>Recent Transactions</h3>
            <table className='table w-full mt-3'>
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>TEDS</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                {transactions && transactions.length > 0 ? (
                  transactions.map((transaction) => (
                    <tr key={transaction._id}>
                      <td>{transaction.fromUser.userName}</td>
                      <td>{transaction.toUser.userName}</td>
                      <td>{transaction.tedsAmount}</td>
                      <td>{isNaN(transaction.cost) || transaction.cost == null ? 'N/A' : `£${transaction.cost.toFixed(2)}`}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='4'>No recent transactions found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default TEDSWallet
