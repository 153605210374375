import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import membersService from './membersService'

const initialState = {
  members: [],
  memberTEDSTransactions: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// *Fetch Members
export const getMembers = createAsyncThunk('members/fetch', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await membersService.getMembers(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Partner TEDS Transactions
export const getMemberTEDSTransactions = createAsyncThunk('members/fetch-transactions', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await membersService.getMemberTEDSTransactions(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// The Slice
export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    resetMembersState: (state) => {
      state.memberTEDSTransactions = []
    },
    logout: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.members = action.payload
      })
      .addCase(getMembers.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getMemberTEDSTransactions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMemberTEDSTransactions.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.memberTEDSTransactions = action.payload
      })
      .addCase(getMemberTEDSTransactions.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, logout, resetMembersState } = membersSlice.actions

export default membersSlice.reducer
