import { useState, useEffect } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { agentSignup, reset } from '../features/agents/agentsSlice'
import SubmissionMessage from '../components/SubmissionMessage'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

const AgentSignUp = () => {
  const { message, isLoading, isSuccess, isError, submissionSuccess } = useSelector((state) => state.agents)

  const [selectedCountry, setSelectedCountry] = useState('GB')
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedCountryBusinessAddress, setSelectedCountryBusinessAddress] = useState('GB')
  const [selectedRegionBusinessAddress, setSelectedRegionBusinessAddress] = useState('')
  const [emailError, setEmailError] = useState('')
  const [formErrors, setFormErrors] = useState({})
  console.log('🚀 ~ file: Profile.jsx:17 ~ Profile ~ formErrors:', formErrors)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isValidPostCode, setIsValidPostCode] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isError) {
      console.log(message)
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    country: selectedCountry,
    region: selectedRegion,
    addressLine1BusinessAddress: '',
    addressLine2BusinessAddress: '',
    cityBusinessAddress: '',
    postcodeBusinessAddress: '',
    countryBusinessAddress: selectedCountryBusinessAddress,
    regionBusinessAddress: selectedRegionBusinessAddress,
    website: '',
    landline: '',
    mobile: '',
    vatRegistered: '',
    vatRegNumber: '',
    tradingStyle: '',
    tradingName: '',
    currentTradingActivity: '',
    companyRegNum: '',
    januarySales: '',
    februarySales: '',
    marchSales: '',
    aprilSales: '',
    maySales: '',
    juneSales: '',
    julySales: '',
    augustSales: '',
    septemberSales: '',
    octoberSales: '',
    novemberSales: '',
    decemberSales: '',
  })

  console.log(formData)

  const validateForm = () => {
    const requiredFields = [
      'name',
      'email',
      'addressLine1',
      'city',
      'postcode',
      'country',
      'region',
      'vatRegistered',
      'tradingStyle',
      'tradingName',
      'currentTradingActivity',
      'addressLine1BusinessAddress',
      'cityBusinessAddress',
      'postcodeBusinessAddress',
      'countryBusinessAddress',
      'regionBusinessAddress',
    ]

    if (formData.vatRegistered === 'true') {
      requiredFields.push('vatRegNumber')
    }

    if (formData.tradingStyle === 'Ltd company') {
      requiredFields.push('companyRegNum')
    }

    const errors = {}

    requiredFields.forEach((field) => {
      if (formData[field] === undefined || formData[field] === null || formData[field] === '') {
        // Check if the field is 'logo' and show a different error message
        if (field === 'companyLogo') {
          errors[field] = 'Please include a company logo'
        } else {
          errors[field] = 'This field is required'
        }
      }
    })

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset)
    }

    if (isSuccess) {
      toast.success(message.message)
      dispatch(reset)
    }
  }, [isError, isSuccess, message, dispatch])

  const handleCountryChange = (val) => {
    setSelectedCountry(val)
    setFormData((prevState) => ({
      ...prevState,
      country: val,
    }))
  }

  const handleRegionChange = (val) => {
    setSelectedRegion(val)
    setFormData((prevState) => ({
      ...prevState,
      region: val,
    }))
  }

  const handleCountryChangeBusinessAddress = (val) => {
    setSelectedCountryBusinessAddress(val)
    setFormData((prevState) => ({
      ...prevState,
      countryBusinessAddress: val,
    }))
  }

  const handleRegionChangeBusinessAddress = (val) => {
    setSelectedRegionBusinessAddress(val)
    setFormData((prevState) => ({
      ...prevState,
      regionBusinessAddress: val,
    }))
  }

  const validatePostCode = (postcode) => {
    const regex = new RegExp(/^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i)
    return regex.test(postcode)
  }

  function formatPostcode(p) {
    var postcodeRegEx = /(^[A-Z]{1,2}[0-9]{1,2})([0-9][A-Z]{2}$)/i
    return p.replace(postcodeRegEx, function (match, part1, part2) {
      return part1.toUpperCase() + ' ' + part2.toUpperCase()
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    if (!validateForm() || emailError) {
      console.log('Form is invalid')
      return
    }
    const userData = {
      ...formData,
    }
    console.log(userData, 'userdata')

    dispatch(agentSignup(userData))
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onChange = (e) => {
    let value = e.target.value
    if (e.target.name === 'email') {
      if (!validateEmail(e.target.value)) {
        setEmailError('Invalid email')
      } else {
        setEmailError('')
      }
    } else if (e.target.name === 'postcode') {
      if (e.target.value.length > 6) {
        value = formatPostcode(value)
        setIsValidPostCode(validatePostCode(value))
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <>
      {submissionSuccess ? (
        <SubmissionMessage userName={formData.name} />
      ) : (
        <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md bg-gray-50 pb-20'>
          <div className='p-4 rounded '>
            <h1 className='text-2xl font-bold mb-4'>🌟 Agent application for Loyalty Club PLC 🌟</h1>

            <p className='text-base mb-4'>
              Hello Future Partners! We're thrilled you're considering becoming an Agent with us. At Loyalty Club PLC, we believe in creating win-win
              relationships, and our Agent Program is designed to do just that. By joining us, you're not just another member of the team; you're
              family. If you're accepted, you'll join an elite team focused on transforming the loyalty software landscape.
            </p>

            <h2 className='text-xl font-semibold mb-2'>Why Become an Agent?</h2>
            <ul className='list-disc list-inside mb-4'>
              <li>
                📈 <strong>Financial Growth</strong>: Earn attractive commissions for every client you bring on board.
              </li>
              <li>
                🤝 <strong>Networking</strong>: Build your professional network while working with a diverse range of clients.
              </li>
              <li>
                💡 <strong>Skill Enhancement</strong>: Receive comprehensive training to equip you with the skills to succeed.
              </li>
              <li>
                🎯 <strong>Exclusive Access</strong>: Be the first to know about our new features and offerings.
              </li>
            </ul>

            <h2 className='text-xl font-semibold mb-2'>Who Are We Looking For?</h2>
            <p className='text-base mb-4'>We're seeking individuals who are:</p>
            <ul className='list-disc list-inside mb-4'>
              <li>🌟 Highly Motivated</li>
              <li>🗣 Excellent Communicators</li>
              <li>💼 Sales Driven</li>
              <li>🌐 Network Savvy</li>
            </ul>

            <p className='text-base mb-4'>
              So, if you're ready to take your career to new heights and make a tangible impact in the loyalty software landscape, you're in the right
              place!
            </p>

            <p className='text-base mb-4'>
              Scroll down to fill out the form and kickstart your journey with us. We can't wait to work together to redefine what's possible in the
              loyalty club industry.
            </p>

            <p className='text-base'>To Infinity and Beyond,</p>
            <p className='text-base'>The Loyalty Club PLC Team</p>
          </div>
          <h1 className='font-bold text-2xl md:text-left'>Agent Sign Up</h1>
          <div className='divider'></div>

          <form onSubmit={onSubmit}>
            {/* Name*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>NAME</label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter Full Name (required)'
                id='name'
                name='name'
                value={formData.name}
                onChange={onChange}
              />
            </div>
            {formErrors.name && <p className='text-red-500 text-sm flex'>{formErrors.name}</p>}
            {/* Email */}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>EMAIL ADDRESS</label>

              <input
                type='email'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter Email (required)'
                id='email'
                name='email'
                value={formData.email}
                onChange={onChange}
              />
            </div>
            {formErrors.email && <p className='text-red-500 text-sm flex'>{formErrors.email}</p>}
            {emailError && <p className='text-red-500 text-sm flex'>Invalid email address</p>}

            {/* WEBSITE*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>WEBSITE</label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter web address'
                id='website'
                name='website'
                value={formData.website}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.website && formErrors.website}</p>
            <div className='divider'></div>
            <h1 className='flex font-bold text-lg'>Personal address</h1>
            {/* Address line 1*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm font-titillium-web tracking-wider w-full sm:w-1/5 text-left mb-1 sm:mb-0'>ADDRESS LINE 1 </label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter address line 1 (required)'
                id='addressLine1'
                name='addressLine1'
                value={formData.addressLine1}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.addressLine1 && formErrors.addressLine1}</p>
            {/* Address line 2*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm font-titillium-web tracking-wider w-full sm:w-1/5 text-left mb-1 sm:mb-0'>ADDRESS LINE 2 </label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter address line 2'
                id='addressLine2'
                name='addressLine2'
                value={formData.addressLine2}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm'>{formErrors.addressLine2 && formErrors.addressLine2}</p>
            {/* City*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>CITY</label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter city (required)'
                id='city'
                name='city'
                value={formData.city}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.city && formErrors.city}</p>

            {/* POSTCODE*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>POSTCODE </label>

              <input
                type='text'
                maxLength='7'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter postcode (required)'
                id='postcode'
                name='postcode'
                value={formData.postcode}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.postcode && formErrors.postcode}</p>
            {/* COUNTRY*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>COUNTRY</label>
              <div className='flex w-full'>
                <CountryDropdown
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light '
                  defaultOptionLabel='Select country (required)'
                  priorityOptions={['GB', 'US', 'CA']}
                  showDefaultOption={false}
                  valueType='short'
                />
              </div>
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.country && formErrors.country}</p>
            {/* REGION*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>REGION</label>
              <RegionDropdown
                country={selectedCountry}
                countryValueType='short'
                value={selectedRegion}
                onChange={handleRegionChange}
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                blankOptionLabel='Select region'
                defaultOptionLabel='Select region'
                showDefaultOption={true}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.region && formErrors.region}</p>

            <div className='divider'></div>
            <h1 className='flex font-bold text-lg'>Business address</h1>
            {/* Address line 1*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm font-titillium-web tracking-wider w-full sm:w-1/5 text-left mb-1 sm:mb-0'>ADDRESS LINE 1 </label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter address line 1 (required)'
                id='addressLine1BusinessAddress'
                name='addressLine1BusinessAddress'
                value={formData.addressLine1BusinessAddress}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.addressLine1BusinessAddress && formErrors.addressLine1BusinessAddress}</p>
            {/* Address line 2*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm font-titillium-web tracking-wider w-full sm:w-1/5 text-left mb-1 sm:mb-0'>ADDRESS LINE 2 </label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter address line 2'
                id='addressLine2BusinessAddress'
                name='addressLine2BusinessAddress'
                value={formData.addressLine2BusinessAddress}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm'>{formErrors.addressLine2 && formErrors.addressLine2}</p>
            {/* City*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>CITY</label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter city (required)'
                id='cityBusinessAddress'
                name='cityBusinessAddress'
                value={formData.cityBusinessAddress}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.city && formErrors.city}</p>

            {/* POSTCODE*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>POSTCODE </label>

              <input
                type='text'
                maxLength='7'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter postcode (required)'
                id='postcodeBusinessAddress'
                name='postcodeBusinessAddress'
                value={formData.postcodeBusinessAddress}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.postcode && formErrors.postcode}</p>
            {/* COUNTRY*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>COUNTRY</label>
              <div className='flex w-full'>
                <CountryDropdown
                  value={selectedCountryBusinessAddress}
                  onChange={handleCountryChangeBusinessAddress}
                  className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light '
                  defaultOptionLabel='Select country'
                  priorityOptions={['GB', 'US', 'CA']}
                  showDefaultOption={false}
                  valueType='short'
                />
              </div>
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.country && formErrors.country}</p>
            {/* REGION*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>REGION</label>
              <RegionDropdown
                country={selectedCountryBusinessAddress}
                countryValueType='short'
                value={selectedRegionBusinessAddress}
                onChange={handleRegionChangeBusinessAddress}
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                blankOptionLabel='Select region'
                defaultOptionLabel='Select region'
                showDefaultOption={true}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.region && formErrors.region}</p>

            <div className='divider'></div>

            {/* Landline*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>LANDLINE</label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter a landline number'
                id='landline'
                name='landline'
                value={formData.landline}
                onChange={onChange}
              />
            </div>
            {/* Mobile*/}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>MOBILE</label>

              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter a mobile number'
                id='mobile'
                name='mobile'
                value={formData.mobile}
                onChange={onChange}
              />
            </div>

            {/* Trading Style */}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>TRADING STYLE</label>

              <select
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                id='tradingStyle'
                name='tradingStyle'
                value={formData.tradingStyle}
                onChange={onChange}>
                <option value=''>Select a trading style</option>
                <option value='Trader'>Trader</option>
                <option value='Partnership'>Partnership</option>
                <option value='Ltd company'>Ltd Company</option>
              </select>
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.tradingStyle && formErrors.tradingStyle}</p>

            {/* Company Registration Number */}
            {formData.tradingStyle === 'Ltd company' && (
              // This section is rendered only if the trading style is 'Ltd company'
              <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
                <label className='text-sm w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>COMPANY REG NUM</label>
                <input
                  type='number'
                  className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                  placeholder='Enter Company Reg Num'
                  id='companyRegNum'
                  name='companyRegNum'
                  value={formData.companyRegNum}
                  onChange={onChange}
                />
              </div>
            )}
            <p className='text-red-500 text-sm flex'>{formErrors.companyRegNum && formErrors.companyRegNum}</p>

            {/* Current Trading Activity */}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>CURRENT TRADING ACTIVITY</label>
              <textarea
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Describe Current Trading Activity'
                id='currentTradingActivity'
                name='currentTradingActivity'
                value={formData.currentTradingActivity}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.currentTradingActivity && formErrors.currentTradingActivity}</p>

            {/* Trading Name */}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>TRADING NAME</label>
              <input
                type='text'
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                placeholder='Enter Trading Name'
                id='tradingName'
                name='tradingName'
                value={formData.tradingName}
                onChange={onChange}
              />
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.tradingName && formErrors.tradingName}</p>

            {/* Vat registered */}
            <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
              <label className='text-sm capitalize w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>
                ARE YOU VAT REGISTERED?
              </label>

              <select
                className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                id='vatRegistered'
                name='vatRegistered'
                value={formData.vatRegistered}
                onChange={onChange}>
                <option value=''>Select an option</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </div>
            <p className='text-red-500 text-sm flex'>{formErrors.vatRegistered && formErrors.vatRegistered} </p>

            {/* VAT Registration Number  */}
            {formData.vatRegistered === 'true' && (
              <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
                <label className='text-sm w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>VAT REGISTRATION NUMBER</label>
                <input
                  type='number'
                  className='w-full p-3 mr-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                  placeholder='Enter VAT Registration Number'
                  id='vatRegNumber'
                  name='vatRegNumber'
                  value={formData.vatRegNumber}
                  onChange={onChange}
                />
              </div>
            )}
            <p className='text-red-500 text-sm flex'>{formErrors.vatRegNumber && formErrors.vatRegNumber}</p>

            <h1 className='font-bold text-xl mt-8 flex'>Monthly Sales Projections</h1>
            <div className='divider'></div>

            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4'>
              {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(
                (month, index) => (
                  <div key={index} className='flex flex-col'>
                    <label className='text-sm font-titillium-web tracking-wider mb-1'>{month.toUpperCase()}</label>
                    <select
                      className='w-full p-3 border border-gray-300 rounded-md placeholder:font-sans.placeholder:font-light'
                      id={`${month.toLowerCase()}Sales`}
                      name={`${month.toLowerCase()}Sales`}
                      value={formData[`${month.toLowerCase()}Sales`]}
                      onChange={onChange}
                      required>
                      <option value='' disabled selected>
                        Select Range
                      </option>
                      <option value='0-5'>0-5</option>
                      <option value='5-10'>5-10</option>
                      <option value='10-15'>10-15</option>
                      <option value='15-20'>15-20</option>
                      <option value='20-30'>20-30</option>
                      <option value='30-40'>30-40</option>
                      <option value='40-50'>40-50</option>
                      <option value='50-70'>50-70</option>
                      <option value='70-100'>70-100</option>
                      <option value='100-150'>100-150</option>
                      <option value='150-200'>150-200</option>
                      <option value='200+'>200+</option>
                    </select>
                  </div>
                )
              )}
            </div>
            <div className='flex justify-center'>
              <button className='btn btn-primary justify-center flex mt-8' disabled={isLoading}>
                {isLoading ? (
                  <>
                    <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
                    <span>Saving...</span>
                  </>
                ) : (
                  <span>Submit Application</span>
                )}
              </button>
            </div>
            {isSubmitted && Object.keys(formErrors).length > 0 && (
              <span className='text-red-500 text-xl ml-2 justify-center mt-2 font-bold flex'>Please fill in all required fields</span>
            )}
          </form>
        </div>
      )}
    </>
  )
}

export default AgentSignUp
