import api from '../../api'

const API_URL = '/api/admin/'

const getUnreadNotifications = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await api.get(API_URL + 'notifications/unread', config)
  return response.data
}

const markNotificationAsRead = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await api.put(API_URL + `notifications/${id}/read`, {}, config)
  return response.data
}

const notificationService = {
  getUnreadNotifications,
  markNotificationAsRead,
}

export default notificationService
