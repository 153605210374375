import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getMemberTEDSTransactions, reset, resetMembersState } from '../features/members/membersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
const MemberOverview = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const { memberId, name } = useParams()
  const decodedName = decodeURIComponent(name)

  const {
    memberTEDSTransactions: { transactions, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.members)

  const { user } = useSelector((state) => state.auth)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
      memberId,
    }
    dispatch(getMemberTEDSTransactions(filterData))

    return () => {
      dispatch(resetMembersState())
    }
  }, [dispatch, currentPage, debouncedSearchTerm, memberId])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  function formatTransactionType(transactionType) {
    const words = transactionType.split('-') // Split words on hyphens
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' ') // Join them with spaces
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <h2 className='text-2xl font-bold mb-6'>{decodedName}'s Transaction Overview</h2>
      <div className='flex mb-8 justify-between mt-2'></div>
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search for a transaction by name'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-md  mr-2 '
        />
      </div>
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Transaction From</th>
              <th>Transaction TO</th>
              <th>Cost</th>
              <th>TEDS Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(transactions) || transactions.length === 0 ? (
              <tr>
                <td colSpan='6'>No Transactions found.</td>
              </tr>
            ) : (
              transactions.map((transaction, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={transaction.id}>
                  <td className=''>{formatDate(transaction.createdAt)}</td>
                  <td className=''>{transaction.fromUser.userName}</td>
                  <td className=''>{transaction.toUser.userName}</td>
                  <td>{isNaN(transaction.cost) || transaction.cost == null ? 'N/A' : `${user.currency}${transaction.cost.toFixed(2)}`}</td>
                  <td className=''>
                    {transaction.transactionType === 'stamp-exchange' ? (
                      <p className='text-green-500'>+{transaction.tedsAmount}</p>
                    ) : transaction.transactionType === 'stamp-purchase' || transaction.transactionType === 'reward-exchange' ? (
                      <span className='text-red-500'>-{transaction.tedsAmount}</span>
                    ) : (
                      transaction.tedsAmount
                    )}
                  </td>
                  <td className=''>{formatTransactionType(transaction.transactionType)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default MemberOverview
