import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import { getReferredPartners, reset, markAsContacted } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'

const ReferredPartners = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const { referredPartners, isError, isSuccess, isLoading, message } = useSelector((state) => state.partners)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getReferredPartners(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handleMarkAsContacted = (partnerId) => {
    dispatch(markAsContacted(partnerId))
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'text-yellow-500'
      case 'contacted':
        return 'text-blue-500'
      case 'joined':
        return 'text-green-500'
      case 'declined':
        return 'text-red-500'
      default:
        return 'text-gray-500'
    }
  }

  return (
    <>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='mb-8'>
        <input
          type='text'
          placeholder='Search by name or email'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-xs  mr-2 '
        />
      </div>
      {isLoading ? (
        <Spinner />
      ) : !Array.isArray(referredPartners.partners) || referredPartners.partners.length === 0 ? (
        <div className='pt-8 text-4xl font-bold mb-4'>No referred partners found.</div>
      ) : (
        <div className='overflow-x-auto pb-20'>
          <table className='table mb-16 w-full min-w-full table-zebra'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Business Name</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Referred By</th>
                <th>Customer Referral Code</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {referredPartners.partners.map((partner, index) => (
                <tr className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} key={partner._id}>
                  <td>{new Date(partner.createdAt).toLocaleDateString()}</td>
                  <td>{partner.businessName}</td>
                  <td>{partner.businessAddress}</td>
                  <td>{partner.businessPhone}</td>
                  <td>{partner.businessEmail}</td>
                  <td>{partner.referredBy.name}</td>
                  <td className='font-bold'>{partner.referralCode}</td>
                  <td className={getStatusColor(partner.status)}>{partner.status}</td>
                  <td>
                    {partner.status === 'pending' && (
                      <button onClick={() => handleMarkAsContacted(partner._id)} className='btn-primary btn-sm btn'>
                        Mark as Contacted
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {referredPartners.totalPages > 1 && (
        <Pagination totalPages={referredPartners.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
      )}
    </>
  )
}

export default ReferredPartners
