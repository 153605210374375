import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { getStrategicPartnerDetails, reset } from '../features/strategicPartner/strategicPartnerSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'

function ViewStrategicPartner() {
  const { strategicPartnerId } = useParams()
  const { isLoading, isSuccess, isError, message, strategicPartner } = useSelector((state) => state.strategicPartners)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    dispatch(getStrategicPartnerDetails(strategicPartnerId))
    return () => {
      dispatch(reset())
    }
  }, [isError, message, strategicPartnerId, dispatch])

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return (
      <div className='alert alert-error shadow-lg'>
        <div>
          <svg xmlns='http://www.w3.org/2000/svg' className='stroke-current flex-shrink-0 h-6 w-6' fill='none' viewBox='0 0 24 24'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
          <span>Error: {message}</span>
        </div>
      </div>
    )
  }

  const handleEdit = () => {
    navigate(`/strategic-partners/${strategicPartnerId}/edit`)
  }

  return (
    <>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='max-w-4xl mx-auto'>
        <h1 className='text-3xl font-bold mb-6'>Strategic Partner Details</h1>
        {strategicPartner && (
          <div className='bg-white shadow-md rounded-lg p-6 mb-20'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              <div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Company Name</h2>
                  <p>{strategicPartner.strategicPartner.companyName}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Business Category</h2>
                  <p>{strategicPartner.strategicPartner.businessCategory}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Address</h2>
                  <p>{strategicPartner.strategicPartner.address.addressLine1}</p>
                  {strategicPartner.strategicPartner.address.addressLine2 && <p>{strategicPartner.strategicPartner.address.addressLine2}</p>}
                  <p>{strategicPartner.strategicPartner.address.city}</p>
                  <p>{strategicPartner.strategicPartner.address.region}</p>
                  <p>{strategicPartner.strategicPartner.address.country}</p>
                  <p>{strategicPartner.strategicPartner.address.postcode}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Phone</h2>
                  <p>{strategicPartner.strategicPartner.phone}</p>
                </div>
              </div>
              <div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Email</h2>
                  <p>{strategicPartner.strategicPartner.email}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Website</h2>
                  <p>{strategicPartner.strategicPartner.website}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Contact Person</h2>
                  <p>{strategicPartner.strategicPartner.contactPerson.name}</p>
                  <p>{strategicPartner.strategicPartner.contactPerson.position}</p>
                  <p>{strategicPartner.strategicPartner.contactPerson.email}</p>
                  <p>{strategicPartner.strategicPartner.contactPerson.phone}</p>
                </div>
                <div className='mb-4'>
                  <h2 className='text-xl font-bold'>Notes</h2>
                  <p>{strategicPartner.strategicPartner.notes}</p>
                </div>
              </div>
            </div>
            <div className='mt-8'>
              <button className='btn btn-primary' onClick={handleEdit}>
                Edit Strategic Partner
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ViewStrategicPartner
