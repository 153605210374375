import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import partnersReducer from '../features/partners/partnersSlice'
import agentsReducer from '../features/agents/agentsSlice'
import membersReducer from '../features/members/membersSlice'
import strategicPartnerReducer from '../features/strategicPartner/strategicPartnerSlice'
import tedsReducer from '../features/teds/tedsSlice'
import charityReducer from '../features/charity/charitySlice'
import notificationsReducer from '../features/notifications/notificationSlice'
import resetMiddleware from '../components/resetMiddleware'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    partners: partnersReducer,
    agents: agentsReducer,
    members: membersReducer,
    teds: tedsReducer,
    strategicPartners: strategicPartnerReducer,
    charities: charityReducer,
    notifications: notificationsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(resetMiddleware),
})
