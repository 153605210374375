import { Navigate, Outlet } from 'react-router-dom'
import { useAuthStatus } from '../hooks/useAuthStatus'
import { useSelector } from 'react-redux'
import Spinner from './Spinner'

const PrivateRoute = ({ roles }) => {
  const { isAuthenticated, checkingStatus } = useAuthStatus()

  const { user } = useSelector((state) => state.auth)

  if (checkingStatus) {
    return <Spinner />
  }

  if (!user || !user.role || (roles.length && !roles.includes(user.role))) {
    return <Navigate to='/login' />
  }

  if (isAuthenticated) {
    // Check if the user's role matches one of the allowed roles
    if (roles.includes(user.role)) {
      return <Outlet />
    }
    // If the user's role does not match, redirect to login
    return <Navigate to='/login' />
  }

  // If not authenticated, redirect to login
  return <Navigate to='/login' />
}

export default PrivateRoute
