import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getFailedRegistrations, reset } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
const FailedRegistrations = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const {
    failedRegistrations: { failedRegistrations, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.partners)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getFailedRegistrations(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search  by name, email, postcode or business type'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-md  mr-2  text-xs'
        />
      </div>
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Business Type</th>
              <th>Business Category</th>
              <th>Postcode</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(failedRegistrations) || failedRegistrations.length === 0 ? (
              <tr>
                <td colSpan='6'>No failed registrations found.</td>
              </tr>
            ) : (
              failedRegistrations.map((registration, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={registration._id}>
                  <td className=''>{formatDate(registration.createdAt)}</td>
                  <td className=''>{registration.name}</td>
                  <td className=''>{registration.email}</td>
                  <td className=''>{registration.businessType}</td>
                  <td className=''>{registration.businessCategory}</td>
                  <td className=''>{registration.postcode}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default FailedRegistrations
