import { Link } from 'react-router-dom'
import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getDailyTEDSReport, reset } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'

const TEDSDailyUpdate = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)

  const {
    dailyTEDSReport: { dailyTEDS, totalPages, today },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.partners)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
    }
    dispatch(getDailyTEDSReport(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>

      {/* Display Today's Info */}
      <div className='mb-8'>
        <h2 className='text-xl font-semibold mb-4'>Today's Current Count</h2>
        <div className='grid grid-cols-3 gap-4'>
          <div className='bg-base-100 p-4 rounded-lg'>
            <h3 className='font-semibold'>Partners</h3>
            <p>Total TEDS: {today?.ClientUser?.totalTEDS}</p>
            <p>Total MADTEDS: {today?.ClientUser?.totalMADTEDS}</p>
          </div>
          <div className='bg-base-100 p-4 rounded-lg'>
            <h3 className='font-semibold'>Customer</h3>
            <p>Total TEDS: {today?.Customer?.totalTEDS}</p>
            <p>Total MADTEDS: {today?.Customer?.totalMADTEDS}</p>
          </div>
          <div className='bg-base-100 p-4 rounded-lg'>
            <h3 className='font-semibold'>Loyalty CLub PLC</h3>
            <p>Total TEDS: {today?.AdminUser?.totalTEDS}</p>
            <p>Total MADTEDS: {today?.AdminUser?.totalMADTEDS}</p>
          </div>
        </div>
      </div>

      {/* Table*/}
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Partners Total Teds</th>
              <th>Partners Total Mads</th>
              <th>Customers Total Teds</th>
              <th>Customers Total Mads</th>
              <th>Loyalty Club PLC Total Teds</th>
              <th>Loyalty Club PLC Total Mads</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(dailyTEDS) || dailyTEDS.length === 0 ? (
              <tr>
                <td colSpan='6'>No sales found.</td>
              </tr>
            ) : (
              dailyTEDS.map((day, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={day.id}>
                  <td>{formatDate(day.date)}</td> {/* Apply formatDate here */}
                  <td className=''>{day.partners.totalTEDS}</td>
                  <td className=''>{day.partners.totalMADTEDS}</td>
                  <td className=''>{day.customers.totalTEDS}</td>
                  <td className=''>{day.customers.totalMADTEDS}</td>
                  <td className=''>{day.admin.totalTEDS}</td>
                  <td className=''>{day.admin.totalMADTEDS}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default TEDSDailyUpdate
