// StrategicPartners.js
import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getAllStrategicPartners, reset } from '../features/strategicPartner/strategicPartnerSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'

const StrategicPartners = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const {
    strategicPartners: { strategicPartners, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.strategicPartners)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getAllStrategicPartners(filterData))
    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handleEditStrategicPartner = (partnerId) => {
    console.log(partnerId, 'partner id')
    navigate(`/strategic-partners/${partnerId}/edit`)
  }

  const handleAddStrategicPartner = () => {
    navigate('/strategic-partners/add')
  }

  const handleViewStrategicPartner = (partnerId) => {
    navigate(`/strategic-partner/${partnerId}/view`)
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search by company name, email, or partnership type'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-md mr-2 text-xs'
        />
      </div>
      <div className='flex justify-between'>
        <h1 className='font-bold text-3xl my-3'>Strategic Partners</h1>
        <button className='btn btn-primary' onClick={handleAddStrategicPartner}>
          add strategic partner
        </button>
      </div>

      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Contact person Email</th>
              <th>Contact person hone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(strategicPartners) || strategicPartners.length === 0 ? (
              <tr>
                <td colSpan='4'>No strategic partners found.</td>
              </tr>
            ) : (
              strategicPartners.map((partner, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={partner._id}>
                  <td className=''>{partner.companyName}</td>
                  <td className=''>{partner.contactPerson.email}</td>
                  <td className=''>{partner.contactPerson.phone}</td>
                  <td>
                    <button className='btn btn-sm btn-primary mr-2' onClick={() => handleViewStrategicPartner(partner._id)}>
                      View
                    </button>
                    <button className='btn btn-sm btn-primary' onClick={() => handleEditStrategicPartner(partner._id)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default StrategicPartners
