// Charities.js
import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getAllCharities, reset } from '../features/charity/charitySlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'

const Charities = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const {
    charities: { charities, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.charities)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    console.log('called')
    dispatch(getAllCharities(filterData))
    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const handleEditCharity = (charityId) => {
    navigate(`/charities/${charityId}/edit`)
  }

  const handleAddCharity = () => {
    navigate('/charity/add')
  }

  const handleViewCharity = (charityId) => {
    navigate(`/charity/${charityId}/view`)
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search by charity name, email, or charity type'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-md mr-2 text-xs'
        />
      </div>
      <div className='flex justify-between'>
        <h1 className='font-bold text-3xl my-3'>Charities</h1>
        <button className='btn btn-primary' onClick={handleAddCharity}>
          Add Charity
        </button>
      </div>
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th>Charity Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Charity Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(charities) || charities.length === 0 ? (
              <tr>
                <td colSpan='4'>No charities found.</td>
              </tr>
            ) : (
              charities.map((charity, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={charity._id}>
                  <td className=''>{charity.charityName}</td>
                  <td className=''>{charity.email}</td>
                  <td className=''>{charity.phone}</td>
                  <td className=''>{charity.charityType}</td>
                  <td>
                    <button className='btn btn-sm btn-primary mr-3' onClick={() => handleEditCharity(charity._id)}>
                      Edit
                    </button>
                    <button className='btn btn-sm btn-primary' onClick={() => handleViewCharity(charity._id)}>
                      View
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default Charities
