import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import strategicPartnerService from './strategicPartnerService'

const initialState = {
  strategicPartners: [],
  strategicPartner: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const registerStrategicPartner = createAsyncThunk('sp/register', async (strategicPartnerData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await strategicPartnerService.registerStrategicPartner(strategicPartnerData, token)

    return response // Return new wallet data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const getAllStrategicPartners = createAsyncThunk('sp/get-strategic-partners', async (filterData, thunkAPI) => {
  try {
    console.log('1 go')
    const token = thunkAPI.getState().auth.user.token
    const response = await strategicPartnerService.getAllStrategicPartners(filterData, token)

    return response // Return new wallet data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const getStrategicPartnerDetails = createAsyncThunk('sp/get-sp-details', async (strategicPartnerId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await strategicPartnerService.getStrategicPartnerDetails(strategicPartnerId, token)

    return response // Return new wallet data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateStrategicPartner = createAsyncThunk('sp/update-sp', async (strategicPartnerData, thunkAPI) => {
  try {
    console.log('1 go')
    const token = thunkAPI.getState().auth.user.token
    const response = await strategicPartnerService.updateStrategicPartner(strategicPartnerData, token)

    return response // Return new wallet data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const strategicPartnerSlice = createSlice({
  name: 'strategicPartner',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerStrategicPartner.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(registerStrategicPartner.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
    })
    builder.addCase(registerStrategicPartner.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getAllStrategicPartners.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllStrategicPartners.fulfilled, (state, action) => {
      state.isLoading = false
      state.strategicPartners = action.payload
    })
    builder.addCase(getAllStrategicPartners.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getStrategicPartnerDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getStrategicPartnerDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.strategicPartner = action.payload
    })
    builder.addCase(getStrategicPartnerDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(updateStrategicPartner.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateStrategicPartner.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.strategicPartner = action.payload
    })
    builder.addCase(updateStrategicPartner.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
  },
})

export const { reset } = strategicPartnerSlice.actions

export default strategicPartnerSlice.reducer
