import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import { getSoldTEDSTransactions, acceptTEDSTransaction, reset } from '../features/teds/tedsSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
const SoldTEDSTransactions = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [pendingOnly, setPendingOnly] = useState(false)
  const {
    soldTEDSTransactions: { transactions, totalPages },
    isError,
    isSuccess: isSuccessTEDS,
    isLoading,
    message: messageTEDS,
  } = useSelector((state) => state.teds)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  useEffect(() => {
    if (isError) {
      toast.error(messageTEDS)
      dispatch(reset())
    }
    if (isSuccessTEDS) {
      toast.success(messageTEDS)
      dispatch(reset())
    }
  }, [isSuccessTEDS, dispatch, isError, messageTEDS])
  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
      pendingOnly: pendingOnly,
    }
    dispatch(getSoldTEDSTransactions(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm, pendingOnly])
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }
  const handleAcceptTransaction = (transactionId) => {
    dispatch(acceptTEDSTransaction(transactionId))
  }
  const handlePendingOnlyChange = (event) => {
    setPendingOnly(event.target.checked)
    setCurrentPage(1)
  }
  const getCreatedAtDaysAgo = (createdAt) => {
    const currentDate = new Date()
    const createdAtDate = new Date(createdAt)
    const timeDiff = Math.abs(currentDate.getTime() - createdAtDate.getTime())
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))
    return daysDiff
  }
  return (
    <>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='flex items-center mb-4'>
        <input
          type='text'
          placeholder='Search transactions'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-xs mr-2'
        />
        <label className='flex items-center'>
          <input type='checkbox' checked={pendingOnly} onChange={handlePendingOnlyChange} className='checkbox mr-2' />
          <span>Show pending only</span>
        </label>
      </div>
      {isLoading ? (
        <Spinner />
      ) : !Array.isArray(transactions) || transactions.length === 0 ? (
        <div className='pt-8 text-4xl font-bold mb-4'>No Sold TEDS Transactions found.</div>
      ) : (
        <div className='overflow-x-auto pb-20'>
          <div className='flex mb-8 justify-between mt-2'></div>
          <table className='table mb-16 w-full min-w-full table-zebra'>
            <thead>
              <tr>
                <th>From User</th>
                <th>TEDS #</th>
                <th>MADS #</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Currency</th>
                <th>Currency Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr className={index % 2 === 0 ? 'bg-red-200' : 'bg-blue-100'} key={transaction._id}>
                  <td>
                    {' '}
                    <Link to={`/partner-overview/${transaction.fromUser.userId}`} className=''>
                      <p className='font-bold'>{transaction.fromUser?.userName || ''}</p>
                    </Link>
                  </td>
                  <td>{transaction.tedsAmount}</td>
                  <td>{transaction.madTedsAmount}</td>
                  <td>
                    <div className='flex flex-col '>
                      <p>
                        {new Date(transaction.createdAt).toLocaleString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          hour12: true,
                        })}
                      </p>
                      <p>{getCreatedAtDaysAgo(transaction.createdAt)} days ago</p>
                    </div>
                  </td>
                  <td>{transaction.status}</td>
                  <td>{transaction.currency}</td>
                  <td>{transaction.currency === 'GBP' ? <>£{transaction.currencyValue.toFixed(2)}</> : transaction.currencyValue}</td>
                  <td>
                    {transaction.status !== 'Completed' && (
                      <button onClick={() => handleAcceptTransaction(transaction._id)} className='btn-primary btn-sm btn'>
                        Mark as Paid
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalPages > 1 && <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
        </div>
      )}
    </>
  )
}
export default SoldTEDSTransactions
