import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../components/Spinner'
import { getMembers, reset } from '../features/members/membersSlice'
import { transferAgent, reset as agentsReset } from '../features/agents/agentsSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import moment from 'moment'

const Members = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const { members, isError, isSuccess, isLoading, message } = useSelector((state) => state.members)
  const {
    isSuccess: agentsIsSuccess,
    isLoading: agentsIsLoading,
    message: agentsMessage,
    isError: agentsIsError,
  } = useSelector((state) => state.agents)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const handleColumnVisibilityChange = (columnId) => {
    setColumns(columns.map((col) => (col.id === columnId ? { ...col, isVisible: !col.isVisible } : col)))
  }

  const columnConfig = [
    { id: 'name', label: 'Name', isVisible: true },
    { id: 'createdAt', label: 'Member Since', isVisible: true },
    { id: 'introducedBy', label: 'Introduced By', isVisible: true },
    { id: 'tedsBalance', label: 'TEDS Balance', isVisible: true },
    { id: 'madTedsBalance', label: 'MADS Balance', isVisible: true },
    { id: 'transactionCount', label: 'Transaction Count', isVisible: true },
    { id: 'totalCustomersIntroduced', label: 'Customers Introduced', isVisible: true },
    { id: 'totalPartnersIntroduced', label: 'Partners Introduced', isVisible: true },
  ]

  const [columns, setColumns] = useState(columnConfig)

  useEffect(() => {
    if (isError || agentsIsError) {
      console.log(message)
      toast.error(message)
      dispatch(reset())
    } else if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message, agentsIsSuccess, agentsMessage, agentsIsError, currentPage, debouncedSearchTerm])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getMembers(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const tedsToPounds = (teds) => (teds / 10).toFixed(2)

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      {/* Show and hide columns */}
      <div className='bg-gray-100 p-4 rounded-lg shadow mb-6'>
        <h1 className='font-bold text-xl my-4'> Show & Hide Columns</h1>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          <div>
            <h3 className='font-semibold text-lg mb-3'>Member Info</h3>
            <div className='space-y-2'>
              {columns
                .filter((col) => ['name', 'createdAt'].includes(col.id))
                .map((col) => (
                  <label key={col.id} className='flex items-center space-x-2'>
                    <input
                      type='checkbox'
                      checked={col.isVisible}
                      onChange={() => handleColumnVisibilityChange(col.id)}
                      className='form-checkbox h-5 w-5'
                    />
                    <span>{col.label}</span>
                  </label>
                ))}
            </div>
          </div>

          <div>
            <h3 className='font-semibold text-lg mb-3'>Referral Info</h3>
            <div className='space-y-2'>
              {columns
                .filter((col) => ['introducedBy', 'totalCustomersIntroduced', 'totalPartnersIntroduced'].includes(col.id))
                .map((col) => (
                  <label key={col.id} className='flex items-center space-x-2'>
                    <input
                      type='checkbox'
                      checked={col.isVisible}
                      onChange={() => handleColumnVisibilityChange(col.id)}
                      className='form-checkbox h-5 w-5'
                    />
                    <span>{col.label}</span>
                  </label>
                ))}
            </div>
          </div>

          <div>
            <h3 className='font-semibold text-lg mb-3'>TEDS</h3>
            <div className='space-y-2'>
              {columns
                .filter((col) => ['tedsBalance', 'madTedsBalance', 'transactionCount'].includes(col.id))
                .map((col) => (
                  <label key={col.id} className='flex items-center space-x-2'>
                    <input
                      type='checkbox'
                      checked={col.isVisible}
                      onChange={() => handleColumnVisibilityChange(col.id)}
                      className='form-checkbox h-5 w-5'
                    />
                    <span>{col.label}</span>
                  </label>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* Search bar */}
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search for a member by name or email'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-xs mr-2'
        />
      </div>

      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              {columns
                .filter((col) => col.isVisible)
                .map((col) => (
                  <th key={col.id}>{col.label}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(members.members) || members.members.length === 0 ? (
              <tr>
                <td colSpan='16'>No members found.</td>
              </tr>
            ) : (
              members.members.map((member, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={member._id}>
                  {columns
                    .filter((col) => col.isVisible)
                    .map((col) => {
                      let content
                      switch (col.id) {
                        case 'name':
                          content = (
                            <>
                              <Link to={`/member-overview/${member._id}/${encodeURIComponent(member.name)}`} className=''>
                                <p className='font-bold hover:text-blue-500'>{member.name}</p>
                                <p className='text-gray-400 text-xs'>{member.email}</p>
                              </Link>
                            </>
                          )
                          break
                        case 'createdAt':
                          content = <p>{moment(member.createdAt).format('DD/MM/YYYY')}</p>
                          break
                        case 'introducedBy':
                          content = member.introducedBy ? (
                            <div>
                              {member.introducedBy.referralType === 'FriendReferral' ? (
                                <Link
                                  to={`/member-overview/${member.introducedBy.referralId}/${encodeURIComponent(member.introducedBy.name)}`}
                                  className=''>
                                  <p className='font-bold hover:text-blue-500'>{member.introducedBy.name}</p>
                                  <p className='text-gray-400 text-xs'>{member.introducedBy.referralType}</p>
                                </Link>
                              ) : member.introducedBy.referralType === 'PartnerIntroduction' ? (
                                <Link to={`/partner-overview/${member.introducedBy.referralId}`}>
                                  <p className='font-bold hover:text-blue-500'>{member.introducedBy.name || 'N/A'}</p>
                                  <p className='text-gray-400 text-xs'>{member.introducedBy.referralType}</p>
                                </Link>
                              ) : member.introducedBy.referralType === 'MarketingCampaign' ? (
                                <div>
                                  <p className='font-bold'>{member.introducedBy.name || 'Marketing Campaign'}</p>

                                  {member.utmData && (
                                    <div className='text-xs text-gray-500'>
                                      <p>Source: {member.utmData.source || 'N/A'}</p>
                                      <p>Medium: {member.utmData.medium || 'N/A'}</p>
                                      <p>Campaign: {member.utmData.campaign || 'N/A'}</p>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <p>{member.introducedBy.name || 'N/A'}</p>
                              )}
                            </div>
                          ) : (
                            'N/A'
                          )
                          break
                        case 'tedsBalance':
                          content = (
                            <div className='flex flex-col'>
                              <div className='text-lg'>{member?.wallet?.tedsBalance}</div>
                              <div className='text-xs'>£{tedsToPounds(member?.wallet?.tedsBalance)} </div>
                            </div>
                          )
                          break
                        case 'madTedsBalance':
                          content = (
                            <div className='flex flex-col'>
                              <div className='text-lg'>{member?.wallet?.madTedsBalance}</div>
                              <div className='text-xs'>£{tedsToPounds(member?.wallet?.madTedsBalance)} </div>
                            </div>
                          )
                          break
                        case 'transactionCount':
                          content = <p>{member?.wallet?.transactionCount}</p>
                          break
                        case 'totalCustomersIntroduced':
                          content = <p>{member.keyMetrics?.totalCustomersIntroduced || 0}</p>
                          break
                        case 'totalPartnersIntroduced':
                          content = <p>{member.keyMetrics?.totalPartnersIntroduced || 0}</p>
                          break
                        default:
                          content = 'N/A'
                      }
                      return <td key={`${member._id}-${col.id}`}>{content}</td>
                    })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={members.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default Members
