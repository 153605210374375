import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getAgentsPartners, reset } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'

const MyReferrals = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const { partners, isError, isSuccess, isLoading, message } = useSelector((state) => state.partners)

  const { user } = useSelector((state) => state.auth)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
      agentReferralCode: user.agentReferralCode,
    }
    console.log('1')
    dispatch(getAgentsPartners(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm, user])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  return (
    <div>
      <h1 className='font-bold text-2xl mb-4 flex'>
        Your agent code : <span className='pl-2'>{user.agentReferralCode}</span>
      </h1>
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search for a partner by name or email'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-xs  mr-2 '
        />
      </div>
      <div className='overflow-x-auto pb-20'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th>Business Name</th>
              <th>Client Name</th>
              <th>Industry</th>
              <th>Date Joined</th>
              <th>Subscription Status</th>
              <th>Plan</th>
              <th>Monthly Payments Made</th>
              <th>Yearly Payments Made</th>
              <th>Last Payment Date</th>
              <th>Next Payment Due</th>

              <th>Total commission earned</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <div className='flex'>
                <Spinner />
              </div>
            ) : !Array.isArray(partners.partners) || partners.partners.length === 0 ? (
              <tr>
                <td colSpan='12'>No partners found.</td>
              </tr>
            ) : (
              partners.partners.map((partner, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={partner._id}>
                  <td className='font-bold'>{partner.businessName}</td>
                  <td>{partner.name}</td>
                  <td>{partner.businessType}</td>
                  <td>{new Date(partner.createdAt).toLocaleDateString()}</td>
                  <td>
                    <p className='capitalize'>{partner.subscription?.status}</p>
                  </td>
                  <td>{partner.subscription?.plan === 'monthly' ? 'Monthly' : 'Yearly'}</td>
                  <td>{partner.subscription?.monthlyPaymentsMade}</td>
                  <td>{partner.subscription?.yearlyPaymentsMade}</td>
                  <td>
                    {partner.subscription?.lastPaymentDate && !isNaN(new Date(partner.subscription?.lastPaymentDate))
                      ? new Date(partner.subscription?.lastPaymentDate).toLocaleDateString('en-GB', {
                          // Notice this line was updated.
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })
                      : 'N/A'}
                  </td>

                  <td>
                    {new Date(partner.subscription?.endDate).toLocaleDateString('en-GB', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </td>

                  <td>{(partner.subscription?.totalAgentCommission / 100).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {partners.totalPages > 1 && <Pagination totalPages={partners.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
    </div>
  )
}

export default MyReferrals
