// UserDropdown.js
import React from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { Link, useNavigate } from 'react-router-dom'
import { MdAccountCircle } from 'react-icons/md'
import { FaCoins, FaUser } from 'react-icons/fa'

function UserDropdown({ user, onLogout }) {
  const navigate = useNavigate()

  // Ensure user.wallet is defined before accessing its properties
  const totalTedsBalance = user.wallet ? user.wallet.madTedsBalance + user.wallet.tedsBalance : 0

  // You can adjust this size value as needed
  const iconSize = '2em' // Example size

  return (
    <div className='dropdown dropdown-end'>
      <div tabIndex={0} role='button' className='btn btn-ghost rounded-btn'>
        <MdAccountCircle size={iconSize} className='text-orange-500' /> {/* Added orange color */}
      </div>
      <ul tabIndex={0} className='menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-72 mt-4'>
        {/* Dropdown items */}
        {user.role !== 'ServerUser' && (
          <li>
            <Link to='/teds/wallet' className='flex items-center gap-2'>
              <FaCoins />
              <span>Wallet: {totalTedsBalance} TEDS</span>
            </Link>
          </li>
        )}
        <li>
          <button
            className='dropdown-item'
            onClick={() => {
              onLogout()
              navigate('/login')
            }}>
            <IconContext.Provider value={{ className: 'text-xl' }}>
              <FaSignOutAlt />
            </IconContext.Provider>
            Logout
          </button>
        </li>
      </ul>
    </div>
  )
}

export default UserDropdown
