import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { getCharityDetails, updateCharity, reset } from '../features/charity/charitySlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'

function EditCharity() {
  const { charityId } = useParams()
  console.log('🚀 ~ EditCharity ~ charityId:', charityId)
  const { isLoading, isSuccess, isError, message, charity } = useSelector((state) => state.charities)

  const [formData, setFormData] = useState({
    charityName: '',
    registrationNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    country: '',
    postcode: '',
    phone: '',
    email: '',
    website: '',
    contactPersonName: '',
    contactPersonPosition: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    charityType: '',
    charityCategory: '',
    missionStatement: '',
    donationMethods: [],
    notes: '',
  })

  const {
    charityName,
    registrationNumber,
    addressLine1,
    addressLine2,
    city,
    region,
    country,
    postcode,
    phone,
    email,
    website,
    contactPersonName,
    contactPersonPosition,
    contactPersonEmail,
    contactPersonPhone,
    charityType,
    charityCategory,
    missionStatement,
    donationMethods,
    notes,
  } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      toast.error(message)
    } else if (charity && charity.charity) {
      const {
        charity: {
          charityName,
          registrationNumber,
          address,
          phone,
          email,
          website,
          contactPerson,
          charityType,
          charityCategory,
          missionStatement,
          donationMethods,
          notes,
        },
      } = charity

      setFormData({
        charityName,
        registrationNumber,
        addressLine1: address?.addressLine1,
        addressLine2: address?.addressLine2,
        city: address?.city,
        region: address?.region,
        country: address?.country,
        postcode: address?.postcode,
        phone,
        email,
        website,
        contactPersonName: contactPerson?.name,
        contactPersonPosition: contactPerson?.position,
        contactPersonEmail: contactPerson?.email,
        contactPersonPhone: contactPerson?.phone,
        charityType,
        charityCategory,
        missionStatement,
        donationMethods,
        notes,
      })
    }

    if (isSuccess) {
      toast.success('Charity Updated Successfully')
      navigate('/charities')
    }

    return () => {
      dispatch(reset())
    }
  }, [isError, isSuccess, message, navigate, dispatch, charityId, charity])

  useEffect(() => {
    dispatch(getCharityDetails(charityId))
  }, [dispatch, charityId])

  const onChange = (e) => {
    if (e.target.name === 'donationMethods') {
      const selectedMethods = Array.from(e.target.selectedOptions, (option) => option.value)
      setFormData((prevState) => ({
        ...prevState,
        donationMethods: selectedMethods,
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }))
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const charityData = {
      charityId,
      charityName,
      registrationNumber,
      address: {
        addressLine1,
        addressLine2,
        city,
        region,
        country,
        postcode,
      },
      phone,
      email,
      website,
      contactPerson: {
        name: contactPersonName,
        position: contactPersonPosition,
        email: contactPersonEmail,
        phone: contactPersonPhone,
      },
      charityType,
      charityCategory,
      missionStatement,
      donationMethods,
      notes,
    }

    dispatch(updateCharity(charityData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='flex mb-3'>
        <BackButton />
      </div>
      <div className='md:mx-20'>
        <h1 className='text-3xl md:text-4xl mb-8 font-bold font-mono'>Edit Charity</h1>
        <form onSubmit={onSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div>
              <label htmlFor='charityName' className='block font-bold mb-1 text-left'>
                Charity Name
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Charity Name'
                id='charityName'
                name='charityName'
                value={charityName}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='charityCategory' className='block font-bold mb-1 text-left'>
                Charity Category
              </label>
              <select
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                id='charityCategory'
                name='charityCategory'
                value={charityCategory}
                onChange={onChange}
                required>
                <option value=''>Select Charity Category</option>
                <option value='animals'>Animals</option>
                <option value='children'>Children</option>
                <option value='education'>Education</option>
                <option value='environment'>Environment</option>
                <option value='health'>Health</option>
                <option value='humanitarian'>Humanitarian</option>
                <option value='poverty'>Poverty</option>
                <option value='other'>Other</option>
              </select>
            </div>

            <div>
              <label htmlFor='registrationNumber' className='block font-bold mb-1 text-left'>
                Registration Number
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Registration Number'
                id='registrationNumber'
                name='registrationNumber'
                value={registrationNumber}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='addressLine1' className='block font-bold mb-1 text-left'>
                Address Line 1
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Address Line 1'
                id='addressLine1'
                name='addressLine1'
                value={addressLine1}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='addressLine2' className='block font-bold mb-1 text-left'>
                Address Line 2
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Address Line 2'
                id='addressLine2'
                name='addressLine2'
                value={addressLine2}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='city' className='block font-bold mb-1 text-left'>
                City
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter City'
                id='city'
                name='city'
                value={city}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='region' className='block font-bold mb-1 text-left'>
                Region
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Region'
                id='region'
                name='region'
                value={region}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='country' className='block font-bold mb-1 text-left'>
                Country
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Country'
                id='country'
                name='country'
                value={country}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='postcode' className='block font-bold mb-1 text-left'>
                Postcode
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Postcode'
                id='postcode'
                name='postcode'
                value={postcode}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='phone' className='block font-bold mb-1 text-left'>
                Phone Number
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Phone Number'
                id='phone'
                name='phone'
                value={phone}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='email' className='block font-bold mb-1 text-left'>
                Email Address
              </label>
              <input
                type='email'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Email Address'
                id='email'
                name='email'
                value={email}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='website' className='block font-bold mb-1 text-left'>
                Website
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Website'
                id='website'
                name='website'
                value={website}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='contactPersonName' className='block font-bold mb-1 text-left'>
                Contact Person Name
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Contact Person Name'
                id='contactPersonName'
                name='contactPersonName'
                value={contactPersonName}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='contactPersonPosition' className='block font-bold mb-1 text-left'>
                Contact Person Position
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Contact Person Position'
                id='contactPersonPosition'
                name='contactPersonPosition'
                value={contactPersonPosition}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='contactPersonEmail' className='block font-bold mb-1 text-left'>
                Contact Person Email
              </label>
              <input
                type='email'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Contact Person Email'
                id='contactPersonEmail'
                name='contactPersonEmail'
                value={contactPersonEmail}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='contactPersonPhone' className='block font-bold mb-1 text-left'>
                Contact Person Phone
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Contact Person Phone'
                id='contactPersonPhone'
                name='contactPersonPhone'
                value={contactPersonPhone}
                onChange={onChange}
              />
            </div>
          </div>

          <div>
            <label htmlFor='charityType' className='block font-bold mb-1 text-left'>
              Charity Type
            </label>
            <select
              className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
              id='charityType'
              name='charityType'
              value={charityType}
              onChange={onChange}
              required>
              <option value=''>Select Charity Type</option>
              <option value='local'>Local</option>
              <option value='national'>National</option>
              <option value='international'>International</option>
            </select>
          </div>

          <div>
            <label htmlFor='missionStatement' className='block font-bold mb-1 text-left'>
              Mission Statement
            </label>
            <textarea
              className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
              placeholder='Enter Mission Statement'
              id='missionStatement'
              name='missionStatement'
              value={missionStatement}
              onChange={onChange}
              rows={4}></textarea>
          </div>

          <div>
            <label htmlFor='donationMethods' className='block font-bold mb-1 text-left'>
              Donation Methods
            </label>
            <p>Hold down CTRL or CMD to select multiple</p>
            <select
              className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
              id='donationMethods'
              name='donationMethods'
              value={donationMethods}
              onChange={onChange}
              multiple
              required>
              <option value=''>Select Donation Methods</option>
              <option value='online'>Online</option>
              <option value='bank_transfer'>Bank Transfer</option>
              <option value='cheque'>Cheque</option>
              <option value='cash'>Cash</option>
            </select>
          </div>

          <div>
            <label htmlFor='notes' className='block font-bold mb-1 text-left'>
              Notes
            </label>
            <textarea
              className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
              placeholder='Enter Notes'
              id='notes'
              name='notes'
              value={notes}
              onChange={onChange}
              rows={4}></textarea>
          </div>

          <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
            <button className='flex p-4 md:p-6 bg-primary text-white w-full md:w-auto font-bold justify-center items-center space-x-4 font-sans rounded-md shadow-lg px-9 shadow-violet-100 hover:bg-opacity-90 hover:shadow-lg border transition hover:-translate-y-0.5 duration-150 mb-12'>
              <span>Update Charity</span>
              <img src='images/next-arrow.svg' alt='' />
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditCharity
