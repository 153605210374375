import React from 'react'

const SubmissionMessage = ({ userName }) => {
  return (
    <div className='flex flex-col items-center justify-center  bg-white h-96 p-4 rounded-lg text-center'>
      <h1 className='text-xl font-bold'>🎉 Thank you for your submission, {userName}! 🎉</h1>
      <p>Your application has been successfully submitted and is awaiting approval from one of the team.</p>
      <p>We typically review submissions within 48 hours.</p>
      <p>
        If you have any questions, feel free to contact us at <a href='mailto:info@ourloyaltyclub.com'>info@ourloyaltyclub.com</a>.
      </p>
    </div>
  )
}

export default SubmissionMessage
