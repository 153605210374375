import { logout } from '../features/auth/authSlice'
import { logout as resetAgents } from '../features/agents/agentsSlice'
import { logout as resetPartners } from '../features/partners/partnersSlice'

const resetMiddleware = (store) => (next) => (action) => {
  if (action.type === logout.fulfilled.type) {
    store.dispatch(resetAgents())
    store.dispatch(resetPartners())
  }
  return next(action)
}

export default resetMiddleware
