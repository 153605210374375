import React, { useEffect, useState } from 'react'
import { verifyToken, resendVerificationEmail } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'

function VerifyEmail() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let [searchParams] = useSearchParams()
  let token = searchParams.get('token')
  const { isLoading, isError, isSuccess, errorMessage, emailResent } = useSelector((state) => state.subscription)
  const [cooldown, setCooldown] = useState(0)

  useEffect(() => {
    dispatch(verifyToken(token))
  }, [dispatch, token])

  const handleResendEmail = () => {
    if (cooldown === 0) {
      dispatch(resendVerificationEmail())
      setCooldown(60)
    }
  }

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(cooldown - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [cooldown])

  if (isLoading) {
    return <div>Loading...</div>
  }
  return (
    <div className='flex flex-col items-center justify-center space-y-4 p-8 bg-white m-12 rounded-2xl'>
      <h1 className='text-3xl font-bold'>Email Verification</h1>
      {isSuccess ? (
        <div className='flex flex-col items-center justify-center space-y-4 p-8 bg-white m-12 rounded-2xl'>
          <h2 className='text-2xl font-bold underline text-green-500'>Email Verified Successfully!</h2>
          <button className='btn btn-secondary' onClick={() => navigate('/login')}>
            Proceed to Home Page
          </button>
        </div>
      ) : isError ? (
        <>
          <h2 className='text-2xl font-bold underline text-red-400'>Verification Failed</h2>
          <p className='text-red-600'>{errorMessage.error}</p>
          <p>
            Please ensure you clicked or copied the entire link. If the problem persists, <a href='/contact_support'>contact our support team</a>.
          </p>
          <button onClick={handleResendEmail} className='btn btn-secondary' disabled={cooldown > 0}>
            {cooldown > 0 ? `Resend in ${cooldown} s` : 'Resend Verification Email'}
          </button>
        </>
      ) : emailResent ? (
        <>
          <h2 className='text-2xl font-bold text-green-500'>Verification Email Resent Successfully!</h2>
          <p>
            Please check your inbox and follow the instructions in the email to complete the verification process. If you can't find the email, please
            check your spam or junk folder. It might take a few minutes for the email to arrive.
          </p>
        </>
      ) : (
        <>
          <h2 className='text-2xl font-bold text-red-500'>Email Verification Unsuccessful</h2>
          <p className='text-red-600'>The token is either invalid or expired.</p>
          <button onClick={handleResendEmail} className='btn btn-secondary'>
            Resend Verification Email
          </button>
        </>
      )}
    </div>
  )
}

export default VerifyEmail
