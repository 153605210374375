import { Link } from 'react-router-dom'
import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { getSales, reset } from '../features/partners/partnersSlice'
import Pagination from '../components/Pagination'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from '../components/useDebounce'
const AgentSales = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const navigate = useNavigate()

  const {
    sales: { sales, totalPages },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.partners)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getSales(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    const date = new Date(isoString)
    return date.toLocaleDateString('en-GB', options)
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search for a partner by name or email'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-xs  mr-2 '
        />
      </div>
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Customer Name</th>
              <th>Business Name</th>
              <th>Sale Amount</th>
              <th>Commission earned</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <div>
                <Spinner />
              </div>
            ) : !Array.isArray(sales) || sales.length === 0 ? (
              <tr>
                <td colSpan='6'>No sales found.</td>
              </tr>
            ) : (
              sales.map((sale, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={sale.id}>
                  <td className=''>{formatDate(sale.createdAt)}</td>
                  <td className=''>{sale.customerName}</td>
                  <td className=''>{sale.businessName}</td>
                  <td className=''>{`£${(sale.amount / 100).toFixed(2)}`}</td>
                  <td className=''>{`£${(sale.agentCommission / 100).toFixed(2)}`}</td>
                  <td className=''>{sale.status}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )
}

export default AgentSales
