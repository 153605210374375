import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/agents`

const ADMIN_URL = `${REACT_APP_SERVER_URL}/api/admin`

// *login a new user
const agentSignup = async (userData) => {
  console.log(userData)
  const response = await api.post(API_URL + '/agent-signup', userData)

  return response.data
}

const getAgents = async (token, { page = 1, limit = 10, searchTerm = '', isActive }) => {
  console.log(searchTerm, 'search term')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${ADMIN_URL}/get-all-agents?page=${page}&limit=${limit}&searchTerm=${searchTerm}&isActive=${isActive}`
    const agents = await api.get(url, config)

    return agents.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getAgent = async (token, agentId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    let url
    if (agentId) {
      url = `${API_URL}/get-agent/${agentId}`
    } else {
      url = `${API_URL}/get-agent`
    }

    const agents = await api.get(url, config)

    return agents.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Accept agent via ID
// Accept agent via ID
const acceptAgent = async (agentID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${ADMIN_URL}/accept-agent/${agentID}`, null, config)
    return response.data
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    console.error(`An error occurred while accepting the agent: ${message}`)
    throw new Error(message)
  }
}

// Transfer agent using agentData
// Transfer agent using agentData
const transferAgent = async (agentData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
    const response = await api.put(`${ADMIN_URL}/transfer-agent`, agentData, config)
    return response.data
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    console.error(`An error occurred while transferring the agent: ${message}`)
    throw new Error(message)
  }
}

const agentService = {
  getAgents,
  getAgent,
  agentSignup,
  acceptAgent,
  transferAgent,
}

export default agentService
