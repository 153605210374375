import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { resetPassword, reset, resetEmailSent } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const [emailAddress, setEmailAddress] = useState('')

  const [cooldown, setCooldown] = useState(0)

  const { isSuccess, isError, passwordResetEmail, message } = useSelector((state) => state.auth)

  const handleEmailChange = (e) => {
    setEmailAddress(e.target.value)
  }

  useEffect(() => {
    if (isSuccess) {
      setCooldown(60)
      dispatch(reset())
    }

    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
  })

  const sendResetLink = () => {
    if (cooldown === 0) {
      dispatch(resetPassword(emailAddress))

      return
    }

    dispatch(resetPassword(emailAddress))
  }

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(cooldown - 1)
      }, 1000)
      return () => clearInterval(timer)
    }
    dispatch(resetEmailSent())
  }, [cooldown, dispatch])

  return (
    <>
      <div className='flex mb-4 ml-4'>
        <BackButton />
      </div>
      <div className='flex justify-center items-center bg-gray-100'>
        <div className='card w-96 bg-base-100 shadow-xl'>
          <div className='card-body '>
            <h2 className='card-title flex justify-center'>Forgot password?</h2>
            <p className='pb-3 pt-3'>Enter the email address associated with your account and we will send you a link to reset your password.</p>
            <div>
              <input
                type='email'
                className='input input-bordered w-full'
                placeholder='Enter email'
                value={emailAddress}
                onChange={handleEmailChange}
              />
              {cooldown > 0 && (
                <p className='text-green-600 font-bold pt-2 pb-2'>
                  Password reset email has been dispatched. The digital pigeons are on the way to your inbox.
                </p>
              )}
            </div>
            <div className='card-actions justify-end'>
              <button className='btn btn-primary w-full' onClick={sendResetLink} disabled={cooldown > 0}>
                {cooldown > 0 ? `Resend in ${cooldown} seconds` : 'Request Password Reset'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
