import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { VscSettings } from 'react-icons/vsc'
import { GiHamburgerMenu } from 'react-icons/gi'
import { BsGraphUpArrow } from 'react-icons/bs'
import { FaRegHandshake, FaBriefcase } from 'react-icons/fa'

function BottomNav() {
  const { user } = useSelector((state) => state.auth)

  const itemsForAdmin = [
    { link: '/', Icon: GiHamburgerMenu, label: 'Home' },

    { link: '/all-sales', Icon: BsGraphUpArrow, label: 'Sales' },
    { link: '/partners', Icon: FaRegHandshake, label: 'Partners' },
    { link: '/agents', Icon: FaBriefcase, label: 'Agents' },
  ]

  const itemsForAgent = [
    { link: '/', Icon: GiHamburgerMenu, label: 'Home' },

    { link: '/agent-sales', Icon: BsGraphUpArrow, label: 'Sales' },
    { link: '/my-referrals', Icon: FaRegHandshake, label: 'My Referrals' },
    { link: '/settings', Icon: VscSettings, label: 'Settings' },
  ]

  // Determine which items to display based on the user's role
  let items = []
  if (user._id) {
    if (user.role === 'AdminUser') {
      items = itemsForAdmin
    } else if (user.role === 'AgentUser') {
      items = itemsForAgent
    }
  }

  return (
    <div className='fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600'>
      <div className='grid h-full max-w-lg grid-cols-5 mx-auto font-medium'>
        {items.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            className='inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group'>
            <item.Icon className='text-gray-500 text-2xl dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary' />
            <span className='text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary'>
              {item.label}
            </span>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default BottomNav
