import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { getStrategicPartnerDetails, updateStrategicPartner, reset } from '../features/strategicPartner/strategicPartnerSlice'
import Spinner from '../components/Spinner'
import BackButton from '../components/BackButton'

function EditStrategicPartner() {
  const { strategicPartnerId } = useParams()
  const { isLoading, isSuccess, isError, message, strategicPartner } = useSelector((state) => state.strategicPartners)

  const [formData, setFormData] = useState({
    companyName: '',
    businessCategory: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    country: '',
    postcode: '',
    phone: '',
    email: '',
    website: '',
    contactPersonName: '',
    contactPersonPosition: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    notes: '',
  })

  const {
    companyName,
    businessCategory,
    addressLine1,
    addressLine2,
    city,
    region,
    country,
    postcode,
    phone,
    email,
    website,
    contactPersonName,
    contactPersonPosition,
    contactPersonEmail,
    contactPersonPhone,
    notes,
  } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      toast.error(message)
    } else if (strategicPartner && strategicPartner.strategicPartner) {
      console.log('runs kkk')
      const { companyName, businessCategory, address, phone, email, website, contactPerson, notes } = strategicPartner.strategicPartner
      console.log('🚀 ~ useEffect ~ phone:', phone)
      console.log('🚀 ~ useEffect ~ address:', address)
      console.log('🚀 ~ useEffect ~ companyName:', companyName)

      setFormData({
        companyName,
        businessCategory,
        addressLine1: address?.addressLine1,
        addressLine2: address?.addressLine2,
        city: address?.city,
        region: address?.region,
        country: address?.country,
        postcode: address?.postcode,
        phone,
        email,
        website,
        contactPersonName: contactPerson?.name,
        contactPersonPosition: contactPerson?.position,
        contactPersonEmail: contactPerson?.email,
        contactPersonPhone: contactPerson?.phone,
        notes,
      })
    }

    if (isSuccess) {
      toast.success('Strategic Partner Updated Successfully')
      navigate('/strategic-partners')
    }

    return () => {
      dispatch(reset())
    }
  }, [isError, isSuccess, message, navigate, dispatch, strategicPartnerId, strategicPartner])

  useEffect(() => {
    dispatch(getStrategicPartnerDetails(strategicPartnerId))
  }, [dispatch, strategicPartnerId])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const strategicPartnerData = {
      strategicPartnerId,
      companyName,
      businessCategory,
      address: {
        addressLine1,
        addressLine2,
        city,
        region,
        country,
        postcode,
      },
      phone,
      email,
      website,
      contactPerson: {
        name: contactPersonName,
        position: contactPersonPosition,
        email: contactPersonEmail,
        phone: contactPersonPhone,
      },
      notes,
    }

    dispatch(updateStrategicPartner(strategicPartnerData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='flex mb-3'>
        <BackButton />
      </div>
      <div className='md:mx-20'>
        <h1 className='text-3xl md:text-4xl mb-8 font-bold font-mono'>Edit Strategic Partner</h1>
        <form onSubmit={onSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div>
              <label htmlFor='companyName' className='block font-bold mb-1 text-left'>
                Company Name
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Company Name'
                id='companyName'
                name='companyName'
                value={companyName}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='companyName' className='block font-bold mb-1 text-left'>
                Business Category
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Business Category of SP (eg. Wine)'
                id='businessCategory'
                name='businessCategory'
                value={businessCategory}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='addressLine1' className='block font-bold mb-1 text-left'>
                Address Line 1
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Address Line 1'
                id='addressLine1'
                name='addressLine1'
                value={addressLine1}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='addressLine2' className='block font-bold mb-1 text-left'>
                Address Line 2
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Address Line 2'
                id='addressLine2'
                name='addressLine2'
                value={addressLine2}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='city' className='block font-bold mb-1 text-left'>
                City
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter City'
                id='city'
                name='city'
                value={city}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='region' className='block font-bold mb-1 text-left'>
                Region
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Region'
                id='region'
                name='region'
                value={region}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='country' className='block font-bold mb-1 text-left'>
                Country
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Country'
                id='country'
                name='country'
                value={country}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='postcode' className='block font-bold mb-1 text-left'>
                Postcode
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Postcode'
                id='postcode'
                name='postcode'
                value={postcode}
                onChange={onChange}
                required
              />
            </div>

            <div>
              <label htmlFor='phone' className='block font-bold mb-1 text-left'>
                Phone Number
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Phone Number'
                id='phone'
                name='phone'
                value={phone}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='email' className='block font-bold mb-1 text-left'>
                Email Address
              </label>
              <input
                type='email'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Email Address'
                id='email'
                name='email'
                value={email}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='website' className='block font-bold mb-1 text-left'>
                Website
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Website'
                id='website'
                name='website'
                value={website}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='contactPersonName' className='block font-bold mb-1 text-left'>
                Contact Person Name
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Contact Person Name'
                id='contactPersonName'
                name='contactPersonName'
                value={contactPersonName}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='contactPersonPosition' className='block font-bold mb-1 text-left'>
                Contact Person Position
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Contact Person Position'
                id='contactPersonPosition'
                name='contactPersonPosition'
                value={contactPersonPosition}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='contactPersonEmail' className='block font-bold mb-1 text-left'>
                Contact Person Email
              </label>
              <input
                type='email'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Contact Person Email'
                id='contactPersonEmail'
                name='contactPersonEmail'
                value={contactPersonEmail}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='contactPersonPhone' className='block font-bold mb-1 text-left'>
                Contact Person Phone
              </label>
              <input
                type='text'
                className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
                placeholder='Enter Contact Person Phone'
                id='contactPersonPhone'
                name='contactPersonPhone'
                value={contactPersonPhone}
                onChange={onChange}
              />
            </div>
          </div>

          <div>
            <label htmlFor='notes' className='block font-bold mb-1 text-left'>
              Notes
            </label>
            <textarea
              className='w-full p-4 text-xs md:text-base md:p-6 border border-gray-300 rounded-md placeholder:font-sans placeholder:font-light mb-3'
              placeholder='Enter Notes'
              id='notes'
              name='notes'
              value={notes}
              onChange={onChange}
              rows={4}></textarea>
          </div>

          <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
            <button className='mb-20 flex p-4 md:p-6 bg-primary text-white w-full md:w-auto font-bold justify-center items-center space-x-4 font-sans rounded-md shadow-lg px-9 shadow-violet-100 hover:bg-opacity-90 hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
              <span>Update Strategic Partner</span>
              <img src='images/next-arrow.svg' alt='' />
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditStrategicPartner
