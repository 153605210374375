import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BackButton from '../components/BackButton'
import { toast } from 'react-toastify'

import Spinner from '../components/Spinner'
import { getAgents, reset } from '../features/agents/agentsSlice'
import Pagination from '../components/Pagination'
import SalesDataDisplay from '../components/SalesDataDisplay'
import { Link } from 'react-router-dom'

import { useDebounce } from '../components/useDebounce'

const Agents = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [uniqueWeekLabels, setUniqueWeekLabels] = useState([])
  const [uniqueMonthLabels, setUniqueMonthLabels] = useState([])
  const [uniqueDayLabels, setUniqueDayLabels] = useState([])
  const [uniqueYearLabels, setUniqueYearLabels] = useState([])

  const { agents, isError, isSuccess, isLoading, message } = useSelector((state) => state.agents)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
      isActive: true,
    }
    dispatch(getAgents(filterData))
  }, [dispatch, currentPage, debouncedSearchTerm])

  useEffect(() => {
    if (!agents.agents) return
    const allWeekLabels = agents.agents.reduce((labels, agent) => {
      const newLabels = agent.weeklySales.map((week) => week.weekLabel)
      return [...labels, ...newLabels]
    }, [])
    const masterWeekLabels = [...new Set(allWeekLabels)] // Removes duplicates
    const sortedMasterWeekLabels = masterWeekLabels.sort((a, b) => new Date(b) - new Date(a))
    const lastFiveUniqueWeekLabels = sortedMasterWeekLabels.slice(-5)
    const reversedUniqueWeekLabels = [...lastFiveUniqueWeekLabels].reverse()
    setUniqueWeekLabels(reversedUniqueWeekLabels)
  }, [agents.agents])

  useEffect(() => {
    if (!agents.agents) return
    const allMonthLabels = agents.agents.reduce((labels, agent) => {
      const newLabels = agent.monthlySales.map((month) => month.monthLabel)
      return [...labels, ...newLabels]
    }, [])

    const masterMonthLabels = [...new Set(allMonthLabels)] // Removes duplicates
    const sortedMasterMonthLabels = masterMonthLabels.sort((a, b) => new Date(`${a}-01`) - new Date(`${b}-01`))
    const lastTwelveUniqueMonthLabels = sortedMasterMonthLabels.slice(-12)
    const reversedUniqueMonthLabels = [...lastTwelveUniqueMonthLabels].reverse()

    setUniqueMonthLabels(reversedUniqueMonthLabels)
  }, [agents.agents])

  useEffect(() => {
    if (!agents.agents) return
    const allDayLabels = agents.agents.reduce((labels, agent) => {
      const newLabels = agent.dailySales.map((day) => day.dayLabel)
      return [...labels, ...newLabels]
    }, [])

    const masterDayLabels = [...new Set(allDayLabels)] // Removes duplicates
    const sortedMasterDayLabels = masterDayLabels.sort((a, b) => new Date(b) - new Date(a))
    const lastSevenUniqueDayLabels = sortedMasterDayLabels.slice(-7)

    setUniqueDayLabels(lastSevenUniqueDayLabels)
  }, [agents.agents])

  useEffect(() => {
    if (!agents.agents) return
    const allYearLabels = agents.agents.reduce((labels, agent) => {
      const newLabels = agent.yearlySales.map((year) => year.yearLabel)
      return [...labels, ...newLabels]
    }, [])

    const masterYearLabels = [...new Set(allYearLabels)] // Removes duplicates
    const sortedMasterYearLabels = masterYearLabels.sort((a, b) => new Date(`${a}-01-01`) - new Date(`${b}-01-01`))
    const lastFiveUniqueYearLabels = sortedMasterYearLabels.slice(-5)

    setUniqueYearLabels(lastFiveUniqueYearLabels)
  }, [agents.agents])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  return (
    <>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <input
        type='text'
        placeholder='Search by name, email or city'
        value={searchTerm}
        onChange={handleSearchChange}
        className='input w-full max-w-xs  mr-2 '
      />
      <div className='overflow-x-auto pb-20'>
        <div className='flex mb-8 justify-between mt-2'></div>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th className='stickyaa left-0 bg-white z-50'>Name</th>
              <th>Email</th>
              <th>City</th>
              <th>Region</th>
              <th>Country</th>
              <th>Agent since</th>
              <th>Agent code</th>
              {/* Dynamic Day Headers */}
              {uniqueDayLabels.map((label, index) => (
                <th key={index}>Day - {label}</th>
              ))}
              {/* Dynamic Week Headers */}
              {uniqueWeekLabels.map((label, index) => (
                <th key={index}>{label}</th>
              ))}
              {/* Dynamic Month Headers */}
              {uniqueMonthLabels.map((label, index) => (
                <th key={index}>Month - {label}</th>
              ))}
              {/* Dynamic Year Headers */}
              {uniqueYearLabels.map((label, index) => (
                <th key={index}>Year - {label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner />
            ) : !Array.isArray(agents.agents) || agents.agents.length === 0 ? (
              <tr>
                <td colSpan='21'>No agents found.</td>
              </tr>
            ) : (
              agents.agents.map((agent, index) => {
                return (
                  <tr className={index % 2 === 0 ? 'bg-red-200' : 'bg-blue-100'} key={agent.id}>
                    <td className='sticky left-0 bg-white'>
                      <p className='mb-2'>{agent.name}</p>

                      <p>
                        <Link to={`/agent-info/${agent.id}/${agent.agentReferralCode}`} className='btn btn-primary btn-sm mb-1'>
                          View Partners
                        </Link>
                      </p>
                      <p>
                        <Link to={`/agent-overview/${agent.id}`} className='btn btn-secondary btn-sm'>
                          View Agent Stats
                        </Link>
                      </p>
                    </td>
                    <td>
                      <p className='mb-2'>{agent.email}</p>
                    </td>
                    <td>{agent.city}</td>
                    <td>{agent.region}</td>
                    <td>{agent.country}</td>
                    <td>{new Date(agent.createdAt).toLocaleDateString()}</td>
                    <td className='font-bold text-lg'>{agent.agentReferralCode}</td>

                    {uniqueDayLabels.map((label, labelIndex) => {
                      const dayData = agent.dailySales.find((day) => day.dayLabel === label)
                      if (dayData) {
                        return (
                          <td key={labelIndex}>
                            <td key={labelIndex}>
                              <SalesDataDisplay data={dayData} />
                            </td>
                          </td>
                        )
                      } else {
                        return (
                          <td key={labelIndex}>
                            <p className='font-bold text-red-600'>No Sales Made</p>
                          </td>
                        )
                      }
                    })}

                    {uniqueWeekLabels.map((label, labelIndex) => {
                      const weekData = agent.weeklySales.find((week) => week.weekLabel === label)
                      if (weekData) {
                        return (
                          <td key={labelIndex}>
                            <td key={labelIndex}>
                              <SalesDataDisplay data={weekData} />
                            </td>
                          </td>
                        )
                      } else {
                        return (
                          <td key={labelIndex}>
                            <p className='font-bold text-red-600'>No Sales Made</p>
                          </td>
                        )
                      }
                    })}
                    {uniqueMonthLabels.map((label, labelIndex) => {
                      const monthData = agent.monthlySales.find((month) => month.monthLabel === label)
                      if (monthData) {
                        return (
                          <td key={labelIndex}>
                            <td key={labelIndex}>
                              <SalesDataDisplay data={monthData} />
                            </td>
                          </td>
                        )
                      } else {
                        return (
                          <td key={labelIndex}>
                            <p className='font-bold text-red-600'>No Sales Made </p>
                          </td>
                        )
                      }
                    })}
                    {uniqueYearLabels.map((label, labelIndex) => {
                      const yearData = agent.yearlySales.find((year) => year.yearLabel === label)
                      if (yearData) {
                        return (
                          <td key={labelIndex}>
                            <td key={labelIndex}>
                              <SalesDataDisplay data={yearData} />
                            </td>
                          </td>
                        )
                      } else {
                        return (
                          <td key={labelIndex}>
                            <p className='font-bold text-red-600'>No Sales Made</p>
                          </td>
                        )
                      }
                    })}
                  </tr>
                )
              })
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={agents.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </>
  )
}

export default Agents
