import axios from 'axios'

const { REACT_APP_SERVER_URL } = process.env
const API_URL = `${REACT_APP_SERVER_URL}/api`

const registerStrategicPartner = async (strategicPartnerData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = `${API_URL}/admin/register-strategic-partner`
    const response = await axios.post(url, strategicPartnerData, config)
    return response.data
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.message
      throw new Error(errorMessage)
    }
    throw error
  }
}

const getAllStrategicPartners = async (filterData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        params: filterData,
      },
    }
    const url = `${API_URL}/admin/strategic-partners`
    const response = await axios.get(url, config)
    return response.data
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.message
      throw new Error(errorMessage)
    }
    throw error
  }
}

const getStrategicPartnerDetails = async (strategicPartnerId, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = `${API_URL}/admin/strategic-partner/${strategicPartnerId}`
    const response = await axios.get(url, config)
    return response.data
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.message
      throw new Error(errorMessage)
    }
    throw error
  }
}

const updateStrategicPartner = async (strategicPartnerData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = `${API_URL}/admin/strategic-partners`
    const response = await axios.put(url, strategicPartnerData, config)
    return response.data
  } catch (error) {
    if (error.response) {
      const errorMessage = error.response.data.message
      throw new Error(errorMessage)
    }
    throw error
  }
}

const strategicPartnerService = {
  registerStrategicPartner,
  getAllStrategicPartners,
  getStrategicPartnerDetails,
  updateStrategicPartner,
}

export default strategicPartnerService
