import React from 'react'

const SalesDataDisplay = ({ data }) => {
  return (
    <div className='flex flex-col'>
      <div className='flex justify-between'>
        <div className='font-bold'>Sales</div>
        <div>£{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(data.amount / 100).slice(1)}</div>
      </div>

      <div className='flex justify-between'>
        <div className='font-bold'>Yearly Renewal</div>
        <div>{data.yearlySubscriptionRenewal}</div>
      </div>

      <div className='flex justify-between'>
        <div className='font-bold'>Monthly Renewal</div>
        <div>{data.monthlySubscriptionRenewal}</div>
      </div>

      <div className='flex justify-between'>
        <div className='font-bold mr-4'>Monthly New Subscription</div>
        <div>{data.monthlyNewSubscription}</div>
      </div>

      <div className='flex justify-between'>
        <div className='font-bold'>Yearly New Subscription</div>
        <div>{data.yearlyNewSubscription}</div>
      </div>

      <div className='flex justify-between'>
        <div className='font-bold'>Total commission</div>
        <div>£{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(data.totalCommission / 100).slice(1)}</div>
      </div>
    </div>
  )
}

export default SalesDataDisplay
