import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import charityService from './charityService'

const initialState = {
  charities: [],
  charity: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const registerCharity = createAsyncThunk('charity/register', async (charityData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await charityService.registerCharity(charityData, token)

    return response // Return new charity data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const getAllCharities = createAsyncThunk('charity/get-charities', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await charityService.getAllCharities(filterData, token)

    return response // Return all charities data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const getCharityDetails = createAsyncThunk('charity/get-charity-details', async (charityId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await charityService.getCharityDetails(charityId, token)

    return response // Return charity details data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateCharity = createAsyncThunk('charity/update-charity', async (charityData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await charityService.updateCharity(charityData, token)

    return response // Return updated charity data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const charitySlice = createSlice({
  name: 'charity',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerCharity.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(registerCharity.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
    })
    builder.addCase(registerCharity.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getAllCharities.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllCharities.fulfilled, (state, action) => {
      state.isLoading = false
      state.charities = action.payload
    })
    builder.addCase(getAllCharities.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getCharityDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCharityDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.charity = action.payload
    })
    builder.addCase(getCharityDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(updateCharity.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateCharity.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.charity = action.payload
    })
    builder.addCase(updateCharity.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
  },
})

export const { reset } = charitySlice.actions

export default charitySlice.reducer
