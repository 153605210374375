import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { VscSettings } from 'react-icons/vsc'
import { FaRegHandshake, FaBriefcase, FaSearchDollar, FaRegAngry, FaSortAmountUp } from 'react-icons/fa'
import { BsFillPencilFill, BsPeopleFill } from 'react-icons/bs'
import { FaPuzzlePiece } from 'react-icons/fa'
import { FaHandHoldingHeart } from 'react-icons/fa'
import { authCheck } from '../features/auth/authSlice'

import { IoIosPeople } from 'react-icons/io'

import { MdOutlineQueryStats } from 'react-icons/md'

import { RiHandCoinLine } from 'react-icons/ri'

function Home() {
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(authCheck())
  }, [dispatch])

  const itemsForAdmin = [
    { link: '/partners', title: 'Partners', Icon: FaRegHandshake },
    { link: '/members', title: 'Members', Icon: IoIosPeople },
    { link: '/agents', title: 'Agents', Icon: FaBriefcase },
    { link: '/all-sales', title: 'Sales', Icon: FaSearchDollar },
    { link: '/new-agents', title: 'New Agents', Icon: BsPeopleFill },
    { link: '/partner-referrals', title: 'Referred Partners', Icon: BsPeopleFill },
    { link: '/agent-sign-up', title: 'Agent Signup', Icon: BsFillPencilFill },
    { link: '/strategic-partners', title: 'Strategic Partners', Icon: FaPuzzlePiece },
    { link: '/charities', title: 'Charities', Icon: FaHandHoldingHeart },
    { link: '/failed-registrations', title: 'Failed Registrations', Icon: FaRegAngry },
    { link: '/other-businesses', title: 'Other Businesses', Icon: FaSortAmountUp },
    { link: '/teds', title: 'TEDS Currency Center', Icon: RiHandCoinLine },

    // Add more items for admin
  ]

  const itemsForAgent = [
    { link: '/agent-sales', title: 'Sales', Icon: FaSearchDollar },
    { link: '/agent-overview', title: 'Agent Overview', Icon: MdOutlineQueryStats },
    { link: '/my-referrals', title: 'My Customers', Icon: FaRegHandshake },
    { link: '/settings', title: 'Settings', Icon: VscSettings },
    { link: '/partner-referrals', title: 'Referred Partners', Icon: BsPeopleFill },
  ]
  // Determine which items to display based on the user's role
  const items = user?.role === 'AdminUser' ? itemsForAdmin : itemsForAgent

  return (
    <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-10 pb-20'>
      {items.map((item) => (
        <Link to={item.link} key={item.link} className='group transform transition-transform duration-500 hover:scale-105'>
          <div className='card w-full h-36 bg-base-100 shadow-xl hover:shadow-2xl hover:bg-gray-100'>
            <div className='card-body flex flex-col justify-start items-center'>
              <div>
                <item.Icon size={36} className='mx-auto group-hover:text-primary' />
              </div>

              <h2 className='card-title text-sm group-hover:text-primary'>{item.title}</h2>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default Home
