import React, { useState, useEffect } from 'react'
import { FaBell } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { getUnreadNotifications, markNotificationAsRead } from '../features/notifications/notificationSlice'

function NotificationsDropdown() {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const { notifications, loading } = useSelector((state) => state.notifications)

  useEffect(() => {
    dispatch(getUnreadNotifications())
  }, [dispatch])

  const handleMarkAsRead = (id) => {
    dispatch(markNotificationAsRead(id))
  }

  return (
    <div className='relative'>
      <button onClick={() => setIsOpen(!isOpen)} className='btn btn-ghost btn-circle'>
        <FaBell className='text-orange-500' />
        {notifications.length > 0 && (
          <span className='badge badge-sm indicator-item bg-orange-500 border-orange-500 text-white'>{notifications.length}</span>
        )}
      </button>
      {isOpen && (
        <div className='absolute right-0 mt-2 w-80 bg-base-100 rounded-box shadow-xl z-50 border border-gray-400'>
          <ul className='menu p-2'>
            {loading ? (
              <li className='menu-title'>Loading...</li>
            ) : notifications.length > 0 ? (
              notifications.map((notification) => (
                <li key={notification._id}>
                  <button onClick={() => handleMarkAsRead(notification._id)} className='w-full text-left'>
                    {notification.message}
                  </button>
                </li>
              ))
            ) : (
              <li className='menu-title text-black'>
                <strong className='text-black'>No New Admin notifications</strong>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default NotificationsDropdown
