import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/agents`
const ADMIN_URL = `${REACT_APP_SERVER_URL}/api/admin`

const getPartners = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  console.log(searchTerm, 'search term')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${ADMIN_URL}/partners/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const agents = await api.get(url, config)

    return agents.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getSinglePartner = async (token, partnerId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${ADMIN_URL}/partner/${partnerId}`
    const response = await api.get(url, config)

    return response.data
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getAgentsPartners = async (token, { page = 1, limit = 10, searchTerm = '', agentReferralCode }) => {
  console.log(searchTerm, 'search term')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/get-agents-partners/?page=${page}&limit=${limit}&searchTerm=${searchTerm}&agentReferralCode=${agentReferralCode}`
    const agents = await api.get(url, config)

    return agents.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getSales = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  console.log(searchTerm, 'search term')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/get-sales/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const sales = await api.get(url, config)

    return sales.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getDailyTEDSReport = async (token, { page = 1, limit = 10 }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${ADMIN_URL}/get-daily-teds-report/?page=${page}&limit=${limit}`
    const sales = await api.get(url, config)

    return sales.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getFailedRegistrations = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  console.log(searchTerm, 'search term')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${ADMIN_URL}/get-failed-registrations/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const failedRegistrations = await api.get(url, config)

    return failedRegistrations.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getOtherBusinesses = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  console.log(searchTerm, 'search term')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${ADMIN_URL}/get-other-businesses/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const otherBusinesses = await api.get(url, config)

    return otherBusinesses.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getPartnerTEDSTransactions = async (token, { page = 1, limit = 10, searchTerm = '', partnerId }) => {
  console.log(searchTerm, 'search term')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${REACT_APP_SERVER_URL}/api/teds/get-partner-transactions/?partnerId=${partnerId}&page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const transactions = await api.get(url, config)

    return transactions.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getReferredPartners = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/referred-partners/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const response = await api.get(url, config)

    return response.data
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error(error.response.data.error)
    }
    throw error
  }
}

const markAsContacted = async (token, partnerId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/mark-contacted/${partnerId}`
    const response = await api.put(url, {}, config)

    return response.data
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error(error.response.data.error)
    }
    throw error
  }
}

const partnersService = {
  getPartners,
  getSinglePartner,
  getAgentsPartners,
  getSales,
  getFailedRegistrations,
  getOtherBusinesses,
  getDailyTEDSReport,
  getPartnerTEDSTransactions,
  getReferredPartners,
  markAsContacted,
}

export default partnersService
