import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/teds`

const fetchTedsWallet = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const wallet = await api.get(`${API_URL}/admin/wallet`, config)
    return wallet.data
  } catch (error) {
    if (error.response) {
      // The server responded with a status code outside the 2xx range
      const errorMessage = error.response.data?.error || 'An error occurred while fetching the wallet'
      throw new Error(errorMessage)
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from the server')
    } else {
      // Something happened in setting up the request that triggered an error
      throw new Error('Error setting up the request')
    }
  }
}

const getTEDSTransactions = async (token, { page = 1, limit = 10, searchTerm = '', displayCommissions }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/admin/get-transactions/?page=${page}&limit=${limit}&searchTerm=${searchTerm}&displayCommissions=${displayCommissions}`
    const transactions = await api.get(url, config)

    return transactions.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getDailyWalletActivity = async (token, { date, page = 1, limit = 10 }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/admin/wallet-activity/?date=${date}&page=${page}&limit=${limit}`

    const response = await api.get(url, config)
    return response.data
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getSoldTEDSTransactions = async (token, { page = 1, limit = 10, searchTerm = '', pendingOnly = false }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = `${API_URL}/admin/get-sold-teds-transactions/?page=${page}&limit=${limit}&pendingOnly=${pendingOnly}&searchTerm=${searchTerm}`
    const transactions = await api.get(url, config)
    return transactions.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Accept agent via ID
// Accept agent via ID
const acceptTEDSTransaction = async (transactionId, token) => {
  console.log(transactionId, 'transaction id')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/admin/accept-teds-transaction/${transactionId}`, config)
    return response.data
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    console.error(`An error occurred while accepting the teds transaction: ${message}`)
    throw new Error(message)
  }
}

const tedsService = {
  getTEDSTransactions,
  fetchTedsWallet,
  getDailyWalletActivity,
  getSoldTEDSTransactions,
  acceptTEDSTransaction,
}

export default tedsService
